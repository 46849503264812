import {
    NewsletterSubscription as SourceNewsletterSubscription
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

/** @namespace AllHomePwafe/Component/NewsletterSubscription/Component/NewsletterSubscriptionComponent */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscription {
    /**
     *
     * @override
     */
    renderActions() {
        return (
            <button
              type="submit"
              block="Button"
              mix={ { block: 'FieldForm', elem: 'Button' } }
              aria-label={ __('Submit') }
            >
                { __('SUBSCRIBE') }
            </button>
        );
    }
}

export default NewsletterSubscriptionComponent;
