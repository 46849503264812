import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    NoMatchContainer as SourceNoMatchContainer
} from 'SourceRoute/NoMatch/NoMatch.container';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';

/** @namespace AllHomePwafe/Route/NoMatch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (breadCrumbState) => dispatch(toggleBreadcrumbs(breadCrumbState))
});

/** @namespace AllHomePwafe/Route/NoMatch/Container/NoMatchContainer */
export class NoMatchContainer extends SourceNoMatchContainer {
    componentWillUnmount() {
        const { updateNoMatch } = this.props;
        updateNoMatch(false);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoMatchContainer);
