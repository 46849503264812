import ContentWrapper from 'Component/ContentWrapper';
import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { appendWithStoreCode } from 'Util/Url';
/** @namespace AllHomePwafe/Component/Breadcrumbs/Component/BreadcrumbsComponent */
export class BreadcrumbsComponent extends SourceBreadcrumbs {
    render() {
        const { breadcrumbs, areBreadcrumbsVisible } = this.props;

        if (
            !areBreadcrumbsVisible
            || location.pathname === appendWithStoreCode('/')
            || location.pathname.match(appendWithStoreCode('/account'))
            || location.pathname === '/'
            || location.pathname.match(appendWithStoreCode('/compare'))
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={ { block: 'Breadcrumbs' } } label={ __('Breadcrumbs (current location)...') }>
                <nav aria-label="Breadcrumbs navigation">
                    <ul
                      block="Breadcrumbs"
                      elem="List"
                      itemScope
                      itemType="http://schema.org/BreadcrumbList"
                    >
                        { (
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional
                            breadcrumbs.length
                                ? this.renderBreadcrumbList(breadcrumbs)
                                : this.renderBreadcrumb({}, 0)
                        ) }
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default BreadcrumbsComponent;
