export const SBL_UPDATE_SLIDES = 'SBL_UPDATE_SLIDES';
export const SBL_UPDATE_HOTSPOTS = 'SBL_UPDATE_HOTSPOTS';
export const SBL_CLEAR_SLIDE = 'SBL_CLEAR_SLIDE';
export const SBL_UPDATE_VISIBLE_HOTSPOT_ID = 'SBL_UPDATE_VISIBLE_HOTSPOT_ID';

/** @namespace AllHomePwafe/Store/ShopByLook/Action/clearCurrentSlide */
export const clearCurrentSlide = () => ({ type: SBL_CLEAR_SLIDE });

/** @namespace AllHomePwafe/Store/ShopByLook/Action/updateSlides */
export const updateSlides = (slides) => ({
    type: SBL_UPDATE_SLIDES,
    slides
});

/** @namespace AllHomePwafe/Store/ShopByLook/Action/updateHotSpots */
export const updateHotSpots = ({ hotspots, slide_id }) => ({
    type: SBL_UPDATE_HOTSPOTS,
    hotspots,
    slide_id
});

/** @namespace AllHomePwafe/Store/ShopByLook/Action/updateVisibleHotspotId */
export const updateVisibleHotspotId = (visibleHotspotId) => ({
    type: SBL_UPDATE_VISIBLE_HOTSPOT_ID,
    visibleHotspotId
});
