import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { RESIZE_DEBOUNCE_TIME } from 'Component/ShopByLookImage/ShopByLookImage.config';
import { ShopByLookSlide } from 'Type/ShopByLook';
import { debounce } from 'Util/Debounce';

import ShopByLookImage from './ShopByLookImage.component';

/** @namespace AllHomePwafe/Component/ShopByLookImage/Container/mapStateToProps */
export const mapStateToProps = (state, props) => ({
    slide: { ...state.ShopByLookReducer.slides.find(({ slide_id }) => slide_id === props.slideId) }
});

/** @namespace AllHomePwafe/Component/ShopByLookImage/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace AllHomePwafe/Component/ShopByLookImage/Container/ShopByLookImageContainer */
export class ShopByLookImageContainer extends PureComponent {
    static propTypes = {
        slide: ShopByLookSlide
    };

    static defaultProps = {
        slide: {}
    };

    state = {
        scaleX: -1,
        scaleY: -1,
        image: null
    };

    containerFunctions = {
        onImageLoad: this.onImageLoad.bind(this)
    };

    componentDidMount() {
        window.addEventListener('resize', this.windowResizeDebounced);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeDebounced);
    }

    __construct(props) {
        super.__construct(props);
        this.windowResizeDebounced = debounce(this.onWindowResize, RESIZE_DEBOUNCE_TIME);
    }

    onWindowResize = () => {
        const { image } = this.state;
        if (image) { // if image is loaded then only set the scale
            this.setImageScale(image);
        }
    };

    setImageScale(image) {
        const {
            height, width, naturalHeight, naturalWidth
        } = image;
        const { slide: { hotspots: [{ imgH, imgW } = {}] = [{}] } } = this.props;

        this.setState({
            scaleX: height / (imgH || naturalHeight),
            scaleY: width / (imgW || naturalWidth),
            image
        });
    }

    onImageLoad({ target: image }) {
        this.setImageScale(image);
    }

    render() {
        return (
            <ShopByLookImage
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.state }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopByLookImageContainer);
