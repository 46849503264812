import ProductListPage from 'Component/ProductListPage';
import { ProductList as SourceList } from 'SourceComponent/ProductList/ProductList.component';

import './ProductList.override.style.scss';

/** @namespace AllHomePwafe/Component/ProductList/Component/ProductListComponent */

export class ProductListComponent extends SourceList {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { isWidget, currentPage, device } = this.props;
        const { currentPage: prevCurrentPage } = prevProps;

        // Scroll up on page change, ignore widgets
        if (prevCurrentPage !== currentPage && !isWidget && !device.isMobile) {
            setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0);
        }

        const { isInfiniteLoaderEnabled } = this.props;

        if (isInfiniteLoaderEnabled) {
            this.observePageChange();
        }
    }

    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                  block="ProductList"
                  elem="ProductsMissing"
                >
                    <h2>{ __('Sorry, there were no products that matched your request.') }</h2>
                    <p>{ __('Want to expand your search? Toggle your filters and try again!') }</p>
                </div>
            </div>
        );
    }

    /**
     * @extends to add isMobile Prop
     */

    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            currentPage,
            mix,
            device: { isMobile }
        } = this.props;

        const newProps = this._processProps(props);

        return (
            <ProductListPage
              key={ currentPage }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              isMobile={ isMobile }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...newProps }
            />
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            mix
        } = this.props;

        if (!isLoading && totalPages === 0) {
            return this.renderNoProducts();
        }

        return (
            <div
              block="ProductList"
              mods={ { isLoading } }
              mix={ mix }
            >
                { this.renderTitle() }
                { this.renderLoadButton() }
                { this.renderPages() }
                { this.renderPagination() }
            </div>
        );
    }
}

export default ProductListComponent;
