import {
    CHECKBOX_TYPE,
    ENTER_KEY_CODE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from 'SourceComponent/Field/Field.config';

export const FILE_TYPE = 'file';
export const DATE_TYPE = 'date';

export {
    TEXT_TYPE,
    NUMBER_TYPE,
    RADIO_TYPE,
    CHECKBOX_TYPE,
    TEXTAREA_TYPE,
    PASSWORD_TYPE,
    SELECT_TYPE,
    ENTER_KEY_CODE
};
