/* eslint-disable max-lines */
/* eslint-disable max-len */
import Logo from 'SourceComponent/Logo/Logo.component';

/** @namespace AllHomePwafe/Component/Logo/Component/LogoComponent */
export class LogoComponent extends Logo {
    renderPlaceholderLogo() {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="180.157" height="35" viewBox="0 0 180.157 35">
          <g transform="translate(3265.689 -286.997)">
            <path className="a" d="M-3060.761,287.242h8.465v12.03h8.052V287.317h8.608V321.64h-8.523V308.293h-7.983V321.63h-8.618Z" transform="translate(-142.466 -0.17)" />
            <path className="b" d="M-3232.015,321.589h-9.238l-1.394-3.785h-12.51l-1.439,3.782h-9.094L-3253.546,287h10.048Zm-20.708-12.434h7.524l-3.606-11.675-.38.067Z" />
            <path className="a" d="M-2839.467,343.164h-7.539c0-.475,0-.868,0-1.26-.039-4.419-.032-8.839-.144-13.257a2.353,2.353,0,0,0-2.279-2.564,2.567,2.567,0,0,0-2.674,1.847,9.521,9.521,0,0,0-.364,2.7c-.036,4.114-.016,8.228-.016,12.492h-7.759v-1.674c0-3.812.01-7.625-.014-11.437a11.6,11.6,0,0,0-.248-2.108,2.093,2.093,0,0,0-1.846-1.773,2.2,2.2,0,0,0-2.5,1.039,6.9,6.9,0,0,0-.763,2.876c-.086,3.76-.037,7.523-.038,11.284v1.8h-7.7v-24.5h7.365c.064.806.122,1.539.212,2.669,1.909-1.985,3.919-3.131,6.437-3.132a6.732,6.732,0,0,1,5.941,3.249c.417-.361.753-.645,1.081-.938a8.863,8.863,0,0,1,7.34-2.227c3.146.4,5.25,2.224,5.426,5.806C-2839.244,330.373-2839.467,336.722-2839.467,343.164Z" transform="translate(-272.75 -21.662)" />
            <path className="a" d="M-2940.961,330.623a12.38,12.38,0,0,1-12.168,12.926,12.489,12.489,0,0,1-13.073-12.529,12.5,12.5,0,0,1,12.364-12.959A12.42,12.42,0,0,1-2940.961,330.623Zm-8.024.18a4.966,4.966,0,0,0-4.53-4.849,4.861,4.861,0,0,0-4.6,4.911,4.815,4.815,0,0,0,4.578,4.762A4.928,4.928,0,0,0-2948.985,330.8Z" transform="translate(-208.201 -21.595)" />
            <path className="a" d="M-2729.005,326.618l-13.207,9.276.178.481c4.189,1.4,7.625-.52,11.039-3.025.194,2.636.375,5.1.559,7.6a15.817,15.817,0,0,1-7.4,2.63,14.748,14.748,0,0,1-8.479-1.134,11.08,11.08,0,0,1-6.41-9.184c-.582-5.23.9-9.648,5.334-12.8C-2741.933,316.59-2731.865,317.363-2729.005,326.618Zm-16.5,4.815,7.858-5.675a4.13,4.13,0,0,0-5.14-.658A5.478,5.478,0,0,0-2745.508,331.433Z" transform="translate(-356.527 -21.738)" />
            <path className="b" d="M-3141.163,321.672h-7.8v-34.54h7.8Z" transform="translate(-81.15 -0.094)" />
            <path className="b" d="M-3101.927,321.669h-7.781V287.122h7.781Z" transform="translate(-108.438 -0.087)" />
          </g>
          </svg>
        );
    }
}

export default LogoComponent;
