import { connect } from 'react-redux';

import { MENU } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourcemapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NavigationTabsContainer as SourceNavigationTabsContainer
} from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { updateMeta } from 'Store/Meta/Meta.action';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import browserHistory from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import {
    ACCOUNT_TAB,
    CART_TAB,
    CHECKOUT_TAB, HOME_TAB,
    ORDERTRACKER_TAB
} from './NavigationTabs.config';

export const MenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Menu/Menu.dispatcher'
);

/** @namespace AllHomePwafe/Component/NavigationTabs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    menuStatus: state.MenuReducer.status,
    savedNavStatus: state.MenuReducer.savedNavStatus
});

/** @namespace AllHomePwafe/Component/NavigationTabs/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourcemapDispatchToProps(dispatch),
    setMenuStatus: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setMenuStatus(dispatch, options)
    ),
    setHeaderNavState: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setHeaderNavState(dispatch, options)
    ),
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace AllHomePwafe/Component/NavigationTabs/Container/NavigationTabsContainer */
export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    routeMap = {
        '/track-order': { name: ORDERTRACKER_TAB },
        '/my-account': { name: ACCOUNT_TAB },
        '/checkout': { name: CHECKOUT_TAB, isHidden: true },
        '/cart': { name: CART_TAB },
        '/': { name: HOME_TAB }
    };

    containerFunctions = {
        ...this.containerFunctions,
        onOrderTrackerButtonClick: this.onOrderTrackerButtonClick.bind(this),
        setMenuStatus: this.setMenuStatus.bind(this)
    };

    onOrderTrackerButtonClick() {
        const { pathname } = location;

        this.setSavedNavHeaderStatus();

        if (pathname !== appendWithStoreCode('/track-order')) {
            browserHistory.push(appendWithStoreCode('/track-order'));
        }
    }

    setMenuStatus(status) {
        const { setMenuStatus } = this.props;
        setMenuStatus(status);
    }

    onMenuButtonClick() {
        const {
            setMenuStatus, menuStatus, changeHeaderState, updateMeta, headerState, setHeaderNavState, savedNavStatus
        } = this.props;

        if (!menuStatus) {
            updateMeta({ title: __('Menu') });
            changeHeaderState({
                name: MENU,
                onBackClick: () => browserHistory.goBack()
            });
            setHeaderNavState(headerState);
        } else {
            changeHeaderState({ ...savedNavStatus });
        }

        setMenuStatus(!menuStatus);
    }

    setSavedNavHeaderStatus() {
        const {
            menuStatus, setMenuStatus, changeHeaderState, savedNavStatus
        } = this.props;

        if (menuStatus) {
            setMenuStatus(false);
            changeHeaderState({ ...savedNavStatus });
        }
    }

    onMyAccountButtonClick() {
        this.setSavedNavHeaderStatus();
        super.onMyAccountButtonClick();
    }

    onMinicartButtonClick() {
        this.setSavedNavHeaderStatus();
        super.onMinicartButtonClick();
    }

    onHomeButtonClick() {
        this.setSavedNavHeaderStatus();
        super.onHomeButtonClick();
    }

    handleNavVisibilityOnScroll(windowY) {
        super.handleNavVisibilityOnScroll(windowY);
        const { menuStatus } = this.props;

        const hasHideOnScrollClass = document.documentElement.classList.contains('hideOnScroll');

        if (menuStatus && hasHideOnScrollClass) {
            document.documentElement.classList.remove('hideOnScroll');
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);
