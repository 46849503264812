import { Component } from 'react';

import { TOPBAR_ID } from 'Component/TopBar/Topbar.config';
import CmsBlock from 'SourceComponent/CmsBlock';

import './Topbar.style';

/** @namespace AllHomePwafe/Component/TopBar/Topbar/Component/TopbarComponent */
export class TopbarComponent extends Component {
    render() {
        return (
            <div block="Topbar">
                <CmsBlock identifier={ TOPBAR_ID } />
            </div>
        );
    }
}

export default TopbarComponent;
