import { connect } from 'react-redux';

import { HOME } from 'Route/HomePage/HomePage.config';
import {
    InstallPromptContainer as SourceInstallPromptContainer,
    mapDispatchToProps, mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/InstallPrompt/InstallPrompt.container';
import { onAddToHomeScreenGTMEvent } from 'Util/GTM/GTM';

/** @namespace AllHomePwafe/Component/InstallPrompt/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentStep: state.CurrentStepReducer.step
});

/** @namespace AllHomePwafe/Component/InstallPrompt/Container/InstallPromptContainer */
export class InstallPromptContainer extends SourceInstallPromptContainer {
    render() {
        const { currentStep } = this.props;
        return currentStep === HOME ? super.render() : false;
    }

    handleAppInstall() {
        if (!window.promt_event) {
            return;
        }

        // Show the modal add to home screen dialog
        window.promt_event.prompt();

        // Wait for the user to respond to the prompt
        window.promt_event.userChoice.then(
            /** @namespace AllHomePwafe/Component/InstallPrompt/Container/then */
            (choice) => {
                if (choice.outcome === 'accepted') {
                    onAddToHomeScreenGTMEvent();
                    this.setState({ isBannerClosed: true });
                }

                // Clear the saved prompt since it can't be used again
                window.promt_event = null;
                this.setState({ hasInstallPromptEvent: false });
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallPromptContainer);
