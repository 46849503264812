import ContentWrapper from 'Component/ContentWrapper';
import CmsBlock from 'SourceComponent/CmsBlock';
import { Footer as SourceFooter } from 'SourceComponent/Footer/Footer.component';

import { RENDER_FOOTER_LEFT, RENDER_FOOTER_RIGHT } from './Footer.config';

/** @namespace AllHomePwafe/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    renderMap = {
        [RENDER_FOOTER_LEFT]: {
            render: this.renderLeftColumn
        },
        [RENDER_FOOTER_RIGHT]: {
            render: this.renderRightColumn.bind(this)
        }
    };

    renderLeftColumn(item, i) {
        return (
            <div block="Footer" elem="FooterLeftContent" key={ i }>
                <CmsBlock identifier="kemanapwa_footer_left" />
            </div>
        );
    }

    renderRightColumn(item, i) {
        return (
            <div block="Footer" elem="FooterRightContent" key={ i }>
                <CmsBlock identifier="kemanapwa_footer_right" />
            </div>
        );
    }

    /**
     * isCheckout Mod set
     * @extends
     */

    render() {
        const { isVisibleOnMobile, device, currentStep } = this.props;

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        return (
            <footer block="Footer" aria-label="Footer" mods={ { [currentStep]: !!currentStep } }>
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }

    /**
     * Overridden to remove ScandiPWA Footer Text
     * @override
     */

    renderCopyrightContent() {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
            </ContentWrapper>
        );
    }
}

export default FooterComponent;
