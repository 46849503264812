import { ProductCompareQuery as SourceProductCompareQuery } from 'SourceQuery/ProductCompare.query';
import { isFlashSaleEnabled } from 'Util/FlashSale';

/** @namespace AllHomePwafe/Query/ProductCompare/Query/ProductCompareQuery */
export class ProductCompareQuery extends SourceProductCompareQuery {
    _getProductsFields() {
        const productFields = [
            ...super._getProductsFields(),
            'stock_status',
            'additional_discount',
            this._getCustomizableProductFragment()
        ];

        if (isFlashSaleEnabled()) {
            productFields.push(this._getFlashSaleField());
        }

        return productFields;
    }
}

export default new ProductCompareQuery();
