import { GOOGLE_MAP_LOAD_STATUSES } from 'Component/GoogleMap/GogleMap.config';
import { GM_SETMAP_LOAD_STATUS } from 'Store/GoogleMap/GoogleMap.action';

/** @namespace AllHomePwafe/Store/GoogleMap/Reducer/getInitialState */
export const getInitialState = () => ({
    mapLoadStatus: GOOGLE_MAP_LOAD_STATUSES.PENDING
});

/** @namespace AllHomePwafe/Store/GoogleMap/Reducer/GoogleMapReducer */
export const GoogleMapReducer = (
    state = getInitialState(),
    action
) => {
    const { type, mapLoadStatus } = action;

    switch (type) {
    case GM_SETMAP_LOAD_STATUS:
        return { ...state, mapLoadStatus };
    default:
        return state;
    }
};

export default GoogleMapReducer;
