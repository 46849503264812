import sourceProductReducer, { updateCartTotals as sourceUpdateCartTotals } from 'SourceStore/Cart/Cart.reducer.js';
import {
    REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM,
    SET_CART_LOADING_STATE, UPDATE_TOTALS
} from 'Store/Cart/Cart.action';
import BrowserDatabase from 'Util/BrowserDatabase';

export * from 'SourceStore/Cart/Cart.reducer.js';
export const CART_TOTALS = 'cart_totals';
/** @namespace AllHomePwafe/Store/Cart/Reducer/getInitialState */

export const getInitialState = () => ({
    cartTotals: BrowserDatabase.getItem(CART_TOTALS) || {},
    isLoading: false
});

/** @namespace AllHomePwafe/Store/Cart/Reducer/updateCartTotals */
export const updateCartTotals = (action, state) => {
    const { isLoading } = state;

    return { ...sourceUpdateCartTotals(action, state), isLoading };
};
/** @namespace AllHomePwafe/Store/Cart/Reducer/CartReducer */
export const CartReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_TOTALS:
        return updateCartTotals(action, state);
    case REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM:
        const { cartTotals = { items: [] } } = state;
        const updatedCartTotals = { ...cartTotals };
        // Deep clone the cart items
        const updatedCartItems = JSON.parse(JSON.stringify(updatedCartTotals.items));
        const { productId } = action;

        if (!Object.keys(updatedCartItems).length) {
            return state;
        }

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const index in updatedCartItems) {
            const { product } = updatedCartItems[index];
            const { id } = product;
            const { flash_sale, ...updatedProduct } = product; // Removing the flash sale object here
            if (id === productId) {
                // if flash sale is already removed then return the current state only
                if (!flash_sale) {
                    return state;
                }
                updatedCartItems[index].product = { ...updatedProduct };
            }
        }

        updatedCartTotals.items = updatedCartItems;

        return {
            ...state,
            cartTotals: updatedCartTotals
        };

    case SET_CART_LOADING_STATE:
        const { isLoading = true } = action;
        return {
            ...state,
            isLoading
        };
    default:
        return sourceProductReducer(state, action);
    }
};

export default CartReducer;
