import { SET_HEADER_NAV_STATUS, SET_MENU_STATUS } from './Menu.action';
/** @namespace AllHomePwafe/Store/Menu/Reducer/getInitialState */
export const getInitialState = () => ({
    status: false,
    savedNavStatus: {}
});

/** @namespace AllHomePwafe/Store/Menu/Reducer/MenuStatusReducer */
export const MenuStatusReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_MENU_STATUS:
        const { status } = action;
        return {
            ...state,
            status
        };

    case SET_HEADER_NAV_STATUS:
        const { savedNavStatus } = action;
        return {
            ...state,
            savedNavStatus
        };

    default:
        return state;
    }
};

export default MenuStatusReducer;
