import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductSaleLabel.style';

/** @namespace AllHomePwafe/Component/ProductSaleLabel/Component/ProductSaleLabelComponent */
export class ProductSaleLabelComponent extends PureComponent {
    static propTypes = {
        labelValue: PropTypes.string.isRequired,
        displayPosition: PropTypes.string.isRequired,
        isTextOnly: PropTypes.bool.isRequired
    };

    render() {
        const { labelValue, displayPosition, isTextOnly } = this.props;

        if (isTextOnly) {
            return (
                <div
                  block="ProductSale"
                  elem="LabelTextOnly"
                >
                    { __('You save price %s %', labelValue) }
                </div>
            );
        }

        return (
            <div
              block="ProductSale"
              elem="Label"
              mods={ { displayPosition } }
            >
                { labelValue }
                %
                { ' OFF' }
            </div>
        );
    }
}

export default ProductSaleLabelComponent;
