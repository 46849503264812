import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    FLASH_SALE_STATUSES,
    TIME_UNIT_DISPLAY_DATA
} from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { PERCENT_STATIC } from 'Component/ProductPrice/ProductPrice.config';
import { flashSaleRemainingTimeType, flashSaleType } from 'Type/FlashSale';

import FlashSaleClock from './images/flashsale-clock.svg';

import './FlashSaleTimer.style';

/** @namespace AllHomePwafe/Component/FlashSaleTimer/Component/FlashSaleTimerComponent */
export class FlashSaleTimerComponent extends PureComponent {
    static propTypes = {
        flashSaleRemainingTime: flashSaleRemainingTimeType,
        mods: PropTypes.string.isRequired,
        getClassBasedOnPercentage: PropTypes.func.isRequired,
        flashSale: flashSaleType,
        isValidFlashSale: PropTypes.func.isRequired
    };

    static defaultProps = {
        flashSale: {},
        flashSaleRemainingTime: {}
    };

    renderFlashSaleIcon() {
        return (
            <div block="FlashSaleTimer" elem="Icon">
                <img src={ FlashSaleClock } alt="flash-sale-clock" />
            </div>
        );
    }

    renderFlashSaleTitle() {
        const { flashSale: { deal_status } } = this.props;
        const startEndText = deal_status === FLASH_SALE_STATUSES.RUNNING ? __('will end in') : __('starts in');
        return (
            <div block="FlashSaleTimer" elem="Title">
                <span>{ __('Flash Sale ') }</span>
                { startEndText }
            </div>
        );
    }

    renderFlashSaleTimeUnits() {
        const { flashSaleRemainingTime } = this.props;
        return (
            <div block="FlashSaleTimer" elem="Units">
                { TIME_UNIT_DISPLAY_DATA.map(({ type, title, showColon }) => (
                    this.renderTimeUnit({ leftUnit: flashSaleRemainingTime[type], unitText: title, showColon })
                )) }
            </div>
        );
    }

    renderTimeUnit({ leftUnit, unitText }) {
        return (
            <div block="FlashSaleTimer" elem="Unit">
                <span>{ leftUnit }</span>
                { unitText }
            </div>
        );
    }

    renderFlashSaleLeftQuantity() {
        const { flashSale: { deal_qty_left, product_deal_qty, deal_status }, getClassBasedOnPercentage } = this.props;
        const percentageLeft = (PERCENT_STATIC * Math.max(deal_qty_left, 0)) / product_deal_qty;

        if (deal_status === FLASH_SALE_STATUSES.UPCOMING) {
            return null;
        }

        return (
            <div block="FlashSaleTimer" elem="ProgressBarContainer">
                <div block="FlashSaleTimer" elem="LeftQuantityUpperBar">
                    <div
                      block="FlashSaleTimer"
                      elem="LeftQuantityInnerBar"
                      mods={ { [getClassBasedOnPercentage(percentageLeft)]: true } }
                      style={ { width: `${percentageLeft}%` } }
                    />
                </div>
                { deal_qty_left }
                { __(' items left') }
            </div>

        );
    }

    render() {
        const { mods, isValidFlashSale } = this.props;
        if (!isValidFlashSale()) {
            return null;
        }

        return (
            <div block="FlashSaleTimer" mods={ { [mods]: true } }>
                    { this.renderFlashSaleIcon() }
                    { this.renderFlashSaleTitle() }
                    { this.renderFlashSaleTimeUnits() }
                    { this.renderFlashSaleLeftQuantity() }
            </div>
        );
    }
}

export default FlashSaleTimerComponent;
