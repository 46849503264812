import React, { lazy } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Route } from 'react-router-dom';

import CookiePopup from 'Component/CookiePopup';
import Footer from 'Component/Footer';
import JsonLd from 'Component/JsonLd';
import Meta from 'Component/Meta';
import MyAccountCompleteProfilePopupComponent from 'Component/MyAccountCompleteProfilePopup';
import MyAccountDeleteAccountPopup from 'Component/MyAccountDeleteAccountPopup';
import { SHOP_BY_LOOK_PAGE_URL } from 'Route/ShopByLookListPage/ShopByLookListPage.config';
import SourceRouterComponent, { withStoreRegex } from 'SourceComponent/Router/Router.component';
import { AFTER_ITEMS_TYPE, SWITCH_ITEMS_TYPE } from 'SourceComponent/Router/Router.config';
import history from 'Util/History';

import MyAccountLogoutNotifier from '../MyAccountLogoutNotifier';
import PayInStoreDisclaimerPopup from '../PayInStoreDisclaimerPopup';
import ProductCompareAddSuccessPopup from '../ProductCompareAddSuccessPopup';

// eslint-disable-next-line max-len
export const PaymentCallback = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/PaymentCallback'));
export const TrackPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/TrackPage'));
export const BlogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/BlogPage'));
export const BlogsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/BlogsPage'));
export const StoreLocator = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/StoreLocator')
);
export const ShopByLookListPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ShopByLookListPage')
);
export const ShopByLookDetailPage = lazy(
    () => import(/* webpackMode: "lazy", webpackChunkName: "shopByLook" */ 'Route/ShopByLookDetailPage')
);
// eslint-disable-next-line max-len
export const SitemapPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/SitemapPage'));

// eslint-disable-next-line max-len
export const GoogleLoginRedirect = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "googleLoginRedirect" */'Route/GoogleLoginRedirect'));

// eslint-disable-next-line max-len
export const AppleLoginRedirect = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "appleLoginRedirect" */'Route/AppleLoginRedirect'));

/** @namespace AllHomePwafe/Component/Router/Component/RouterComponent */
export class RouterComponent extends SourceRouterComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer isCheckout={ this.props.isCheckout } />,
            position: 10
        },
        {
            component: <CookiePopup />,
            position: 20
        },
        {
            component: <ProductCompareAddSuccessPopup />,
            position: 25
        },
        {
            component: <MyAccountLogoutNotifier />,
            position: 26
        },
        {
            component: <MyAccountCompleteProfilePopupComponent />,
            position: 27
        },
        {
            component: <MyAccountDeleteAccountPopup />,
            position: 28
        },
        {
            component: <PayInStoreDisclaimerPopup />,
            position: 29
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct() {
        super.__construct();

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
        this[SWITCH_ITEMS_TYPE] = [
            ...this[SWITCH_ITEMS_TYPE],

            {
                component:
                    <Route
                      path={ [withStoreRegex('/payments/:callback'), withStoreRegex('/payments/:callback/index')] }
                      exact
                        // eslint-disable-next-line react/jsx-no-bind
                      render={ (props) => (
                            <PaymentCallback
                                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                              { ...props }
                            />
                      ) }
                    />,
                position: 55
            },

            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/track-order') } render={ (props) => <TrackPage { ...props } key={ Date.now() } /> } />,
                position: 84
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/blog/category/:categoryUrlKey') } render={ (props) => <BlogsPage { ...props } /> } />,
                position: 85
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/blog/:blogUrlKey') } render={ (props) => <BlogPage { ...props } /> } />,
                position: 86
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/blog') } render={ (props) => <BlogsPage { ...props } /> } />,
                position: 87
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex(`/${SHOP_BY_LOOK_PAGE_URL}/:slide_url`) } render={ (props) => <ShopByLookDetailPage { ...props } /> } />,
                position: 88
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex(`/${SHOP_BY_LOOK_PAGE_URL}`) } render={ (props) => <ShopByLookListPage { ...props } /> } />,
                position: 89
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/sitemap') } render={ (props) => <SitemapPage { ...props } /> } />,
                position: 90
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex(`/${this.props.storeLocatorPath}`) } render={ (props) => <StoreLocator { ...props } /> } />,
                position: 91
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/sociallogin/social/callback') } render={ (props) => <GoogleLoginRedirect { ...props } /> } />,
                position: 10 // Set this number so that it can get the more preference over the :account*/login page
            },
            {
                // eslint-disable-next-line react/jsx-no-bind,max-len,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                component: <Route path={ withStoreRegex('/social/login/apple') } render={ (props) => <AppleLoginRedirect { ...props } /> } />,
                position: 11 // Set this number so that it can get the more preference over the :account*/login page
            }
        ];
    }

    /**
     * @override to add JsonLD tags
     */
    render() {
        return (
            <>
                <Meta />
                <JsonLd />
                <ReactRouter history={ history }>
                    { this.renderRouterContent() }
                </ReactRouter>
            </>
        );
    }
}

export default RouterComponent;
