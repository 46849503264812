export const SET_MENU_STATUS = 'SET_MENU_STATUS';
export const SET_HEADER_NAV_STATUS = 'SET_HEADER_NAV_STATUS';
/** @namespace AllHomePwafe/Store/Menu/Action/setMenuStatus */

export const setMenuStatus = (status) => ({
    type: SET_MENU_STATUS,
    status
});

/** @namespace AllHomePwafe/Store/Menu/Action/setHeaderNavState */
export const setHeaderNavState = (savedNavStatus) => ({
    type: SET_HEADER_NAV_STATUS,
    savedNavStatus
});
