export * from 'SourceStore/Checkout/Checkout.action';

export const UPDATE_PICKUP_STORE_LIST = 'UPDATE_PICKUP_STORE_LIST';
export const UPDATE_PAY_IN_STORE_LIST = 'UPDATE_PAY_IN_STORE_LIST';
export const UPDATE_SELECTED_PICKUP_STORE_ID = 'UPDATE_SELECTED_PICKUP_STORE_ID';
export const UPDATE_SELECTED_PAY_IN_STORE_ID = 'UPDATE_SELECTED_PAY_IN_STORE_ID';
export const UPDATE_SELECTED_SHIPPING_METHOD = 'UPDATE_SELECTED_SHIPPING_METHOD';

/** @namespace AllHomePwafe/Store/Checkout/Action/updatePickupStoreList */
export const updatePickupStoreList = (pickupStoreList) => ({
    type: UPDATE_PICKUP_STORE_LIST,
    pickupStoreList
});

/** @namespace AllHomePwafe/Store/Checkout/Action/updatePayInStoreList */
export const updatePayInStoreList = (payInStoreList) => ({
    type: UPDATE_PAY_IN_STORE_LIST,
    payInStoreList
});

/** @namespace AllHomePwafe/Store/Checkout/Action/updateSelectedPickupStoreId */
export const updateSelectedPickupStoreId = (selectedPickupStoreId) => ({
    type: UPDATE_SELECTED_PICKUP_STORE_ID,
    selectedPickupStoreId
});

/** @namespace AllHomePwafe/Store/Checkout/Action/updateSelectedPayInStoreId */
export const updateSelectedPayInStoreId = (selectedPayInStoreId) => ({
    type: UPDATE_SELECTED_PAY_IN_STORE_ID,
    selectedPayInStoreId
});

/** @namespace AllHomePwafe/Store/Checkout/Action/updateSelectedShippingMethod */
export const updateSelectedShippingMethod = (selectedShippingMethod) => ({
    type: UPDATE_SELECTED_SHIPPING_METHOD,
    selectedShippingMethod
});
