import PropTypes from 'prop-types';

import { ProductType } from 'Type/ProductList';

export const ShopByLookSlide = PropTypes.shape({
    title: PropTypes.string,
    image_path: PropTypes.string
});

export const ShopByLookSlides = PropTypes.arrayOf(ShopByLookSlide);

export const SlideHotSpot = PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    product: ProductType,
    productLoadState: PropTypes.string
});
