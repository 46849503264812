import { QueryDispatcher as SourceQueryDispatcher } from 'SourceUtil/Request/QueryDispatcher';
import { makeCancelable } from 'Util/Promise';
import { Field, prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

export * from 'SourceUtil/Request/QueryDispatcher';

/** @namespace AllHomePwafe/Util/Request/QueryDispatcher/QueryDispatcher */
export class QueryDispatcher extends SourceQueryDispatcher {
    onUpdate(data, dispatch, options) {
        // Set the flag for the service worker API response
        this.onSuccess(data, dispatch, options, true);
    }

    handleData(dispatch, options) {
        const { name, cacheTTL } = this;

        const rawQueries = this.prepareRequest(options, dispatch);

        if (!rawQueries) {
            return;
        }

        const queries = rawQueries instanceof Field ? [rawQueries] : rawQueries;

        if (this.promise) {
            this.promise.cancel();
        }

        this.promise = makeCancelable(
            new Promise((resolve, reject) => {
                executeGet(prepareQuery(queries), name, cacheTTL)
                    .then(
                        /** @namespace AllHomePwafe/Util/Request/QueryDispatcher/executeGet/then */
                        (data) => resolve(data),
                        /** @namespace AllHomePwafe/Util/Request/QueryDispatcher/executeGet/then */
                        (error) => reject(error)
                    );
            })
        );

        this.promise.promise.then(
            /** @namespace AllHomePwafe/Util/Request/QueryDispatcher/then */
            (data) => this.onSuccess(data, dispatch, options),
            /** @namespace AllHomePwafe/Util/Request/QueryDispatcher/then */
            (error) => this.onError(error, dispatch, options),
        );
    }
}

export default QueryDispatcher;
