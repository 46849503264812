import Nomatchimage from 'public/images/404.webp';

import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import SourceNoMatchComponent from 'SourceRoute/NoMatch/NoMatch.component';

import './NoMatch.style';

/** @namespace AllHomePwafe/Route/NoMatch/Component/NoMatchComponent */
// eslint-disable-next-line no-undef
export class NoMatchComponent extends SourceNoMatchComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
        this.cleanUpTransition();
    }

    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <img src={ Nomatchimage } alt="404" />

                    <p block="NoMatch" elem="Subtitle">
                        { __('Oops! Page Cannot be Found') }
                    </p>
                    <p block="NoMatch" elem="Text">
                        { /* eslint-disable-next-line max-len */ }
                        { __('Click on the button below to go back to Homepage') }
                    </p>
                    <Link
                      to="/"
                      block="NoMatch"
                      elem="Button"
                      mix={ { block: 'Button' } }
                    >
                        { __('Back to homepage') }
                    </Link>
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatchComponent;
