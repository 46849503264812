export const HOTSPOT_PRODUCT_LOAD_STATUS = {
    SUCCESS: 'SUCCESS',
    LOADING: 'LOADING',
    NO_SKU: 'NO_SKU',
    NO_PRODUCT_FROM_SKU: 'NO_PRODUCT_FROM_SKU',
    ERROR: 'ERROR'
};

export const MIN_DIMENSION = 42;
export const MOBILE_MIN_DIMENSION = 32;
