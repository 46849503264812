import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import { ProductType } from 'Type/ProductList';

import './ProductLabel.style';

/** @namespace AllHomePwafe/Component/ProductLabel/Component/ProductLabelComponent */
export class ProductLabelComponent extends PureComponent {
    static propTypes = {
        isInStock: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,
        product: ProductType.isRequired

    };

    static defaultProps = {
        isLoading: false
    };

    renderSoldOutLabel() {
        const { isInStock } = this.props;
        if (isInStock) {
            return null;
        }

        return (
            <div
              block="ProductLabel"
              elem="SaleItem"
              mods={ { outOfStock: true } }
            >
                { __('SOLD OUT ') }
            </div>
        );
    }

    render() {
        const { isInStock, product, isLoading } = this.props;
        if (isInStock || !product || !product.id) {
            return null;
        }

        return (
            <div
              block="ProductLabel"
            >
                <Loader isLoading={ isLoading } />

                { this.renderSoldOutLabel() }

            </div>
        );
    }
}

export default ProductLabelComponent;
