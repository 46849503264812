import { ProductAttributeValue as SourceProductAttributeValue } from
    'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace AllHomePwafe/Component/ProductAttributeValue/Component/ProductAttributeValueComponent */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderBrand(value, label) {
        return (
            <div>{ label || value || __('N/A') }</div>
        );
    }

    renderAttributeByType() {
        const { attribute: { attribute_type } } = this.props;

        switch (attribute_type) {
        case 'select':
            return this.renderSelectAttribute();
        case 'boolean':
            return this.renderBooleanAttribute();
        case 'text':
            return this.renderTextAttribute();
        case 'weight':
            return this.renderWeightAttribute();
        case 'multiselect':
            return this.renderMultiSelectAttribute();
        case 'media_image':
            return this.renderImageAttribute();
        case 'textarea':
            return this.renderTextAreaAttribute();
        default:
            return this.renderPlaceholder();
        }
    }

    renderWeightAttribute() {
        const { attribute: { attribute_value }, weightUnit } = this.props;
        const converted_attribute_value = `${parseFloat(attribute_value).toFixed(2) } ${weightUnit}`;
        return this.renderStringValue(converted_attribute_value);
    }

    /**
     * @override to add brand Schema and to remove 0 values attributes
     */

    renderStringValue(value, label) {
        const { isFormattedAsText, isSelected, attribute: { attribute_code } } = this.props;
        const isSwatch = label;

        if (value === '0') {
            return false;
        }

        if (isFormattedAsText) {
            return attribute_code === 'brand' ? this.renderBrand(value, label) : label || value || __('N/A');
        }

        if (!isSwatch) {
            return this.renderDropdown(value);
        }

        return (
            <span
              block="ProductAttributeValue"
              elem="String"
              mods={ { isSelected } }
              title={ label }
            >
                { value }
            </span>
        );
    }

    /**
     * @overridden
     * Renders a boolean attribute as a string, either "Yes" or "No".
     * Converts the attribute_value to a number and renders "Yes" if it's truthy, otherwise renders "No".
     * @returns {string} The rendered boolean attribute value.
     */
    renderBooleanAttribute() {
        const { attribute: { attribute_value } } = this.props;
        return this.renderStringValue(+attribute_value ? __('Yes') : __('No'));
    }

    /**
     * @extends to remove empty attributes
     */

    render() {
        const {
            isAvailable
        } = this.props;

        if (!isAvailable) {
            return false;
        }

        return super.render();
    }
}
export default ProductAttributeValueComponent;
