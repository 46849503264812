import { NEWSLETTER_COLUMN, RENDER_NEWSLETTER } from 'SourceComponent/Footer/Footer.config';

export const RENDER_FOOTER_LEFT = 'render_footer_left';
export const RENDER_FOOTER_RIGHT = 'render_footer_right';

export const FOOTER_LEFT = {
    items: [
        {
            render: RENDER_FOOTER_LEFT
        }
    ]
};

export const FOOTER_RIGHT = {
    items: [
        {
            render: RENDER_FOOTER_RIGHT
        }
    ]
};

export const COLUMN_MAP = [
    FOOTER_LEFT,
    FOOTER_RIGHT
];

export { NEWSLETTER_COLUMN, RENDER_NEWSLETTER };
