export const GENDER_OPTIONS = [
    { value: '', label: '-- Select the Title --' },
    {
        value: 1, label: 'Mr', moEngageValue: 'M', id: '1'
    },
    {
        value: 2, label: 'Ms', moEngageValue: 'F', id: '2'
    },
    {
        value: 3, label: 'Mrs', moEngageValue: 'F', id: '3'
    }
];
