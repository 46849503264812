import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/SearchField/SearchField.container';

import SearchField from './SearchField.component';

export const MenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Menu/Menu.dispatcher'
);

/** @namespace AllHomePwafe/Component/SearchField/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMenuStatus: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setMenuStatus(dispatch, options)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
