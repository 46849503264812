import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ShopByLookProductPopup from 'Component/ShopByLookProductPopup';
import { ShopByLookSlide } from 'Type/ShopByLook';

import './ShopByLookImage.style';

/** @namespace AllHomePwafe/Component/ShopByLookImage/Component/ShopByLookImageComponent */
export class ShopByLookImageComponent extends PureComponent {
    static propTypes = {
        slide: ShopByLookSlide,
        onImageLoad: PropTypes.func.isRequired,
        scaleX: PropTypes.number.isRequired,
        scaleY: PropTypes.number.isRequired
    };

    static defaultProps = {
        slide: {}
    };

    render() {
        const {
            slide,
            slide: {
                image_path, title, hotspots = [], slide_id
            }, onImageLoad, scaleX, scaleY
        } = this.props;

        return (
            <div block="ShopByLookImage" mods={ { [`slide-${slide_id}`]: true } }>
                <img
                  src={ image_path }
                  block="ShopByLookImage"
                  elem="Image"
                  alt={ title }
                  onLoad={ onImageLoad }
                />
                { hotspots.map((hotspot) => (
                    <ShopByLookProductPopup
                      key={ hotspot.id }
                      hotspot={ hotspot }
                      slide={ slide }
                      productLoadState={ hotspot.productLoadState }
                      scaleX={ scaleX }
                      scaleY={ scaleY }
                    />
                )) }
            </div>
        );
    }
}

export default ShopByLookImageComponent;
