import { formConfig as sourceFormConfig, validateEmail } from 'SourceComponent/Form/Form.config';

/** @namespace AllHomePwafe/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => (typeof value === 'string' ? value.trim().length > 0 : value);

/** @namespace AllHomePwafe/Component/Form/Config/validateRequiredTermsAndConditions */
export const validateRequiredTermsAndConditions = ({ checked }) => checked === true;

/** @namespace AllHomePwafe/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace AllHomePwafe/Component/Form/Config/validateConfirmPasswordMatch */
export const validateConfirmPasswordMatch = ({ value }, { newPassword }) => {
    const { current: { value: passwordValue } } = newPassword || { current: {} };
    return value === passwordValue;
};

/** @namespace AllHomePwafe/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.length > 0 && value.match(/^[+]?[0-9]{11}$/);

/** @namespace AllHomePwafe/Component/Form/Config/formConfig */
export const formConfig = () => ({
    ...sourceFormConfig(),
    notEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!')
    },
    email: {
        validate: validateEmail,
        message: __('Please enter a valid email address (example: johndoe@domain.com)')
    },
    emails: {
        validate: validateEmails,
        message: __('Please enter a valid email address (example: johndoe@domain.com)')
    },
    requiredTermsAndConditions: {
        validate: validateRequiredTermsAndConditions,
        message: __('Please accept the Terms & Conditions')
    },
    /**
     * New rule added to confirm the new password
     * Rule only will work if the field id equals to newPassword
     */
    validateConfirmPasswordMatch: {
        validate: validateConfirmPasswordMatch,
        message: __('Password does not match.')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Please enter your phone number')
    }
});

export default formConfig();
