import sourceProductReducer, { getInitialState } from 'SourceStore/Product/Product.reducer.js';
import { REMOVE_PRODUCT_FLASH_SALE } from 'Store/Product/Product.action';
import { getPriceRangeWithDiscount } from 'Util/Product/Product';

/** @namespace AllHomePwafe/Store/Product/Reducer/ProductReducer */
export const ProductReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case REMOVE_PRODUCT_FLASH_SALE:
        const { product = {} } = state;

        const { flash_sale, ...updatedProduct } = product;

        if (!flash_sale) {
            return state;
        }

        return {
            ...state,
            product: { ...updatedProduct, price_range: getPriceRangeWithDiscount(updatedProduct) }
        };

    default:
        return sourceProductReducer(state, action);
    }
};

export default ProductReducer;
