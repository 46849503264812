import CategoryPaginationLink from 'Component/CategoryPaginationLink';
import SourceCategoryPaginationComponent
    from 'SourceComponent/CategoryPagination/CategoryPagination.component';

import './CategoryPagination.style';

/** @namespace AllHomePwafe/Component/CategoryPagination/Component/CategoryPaginationComponent */
export class CategoryPaginationComponent extends SourceCategoryPaginationComponent {
    renderPageLink(
        pageNumber,
        label,
        children,
        isCurrent = false
    ) {
        const {
            pathname,
            onPageSelect,
            getSearchQuery,
            isTrackingQueryParam = true
        } = this.props;

        return (
            <li
              key={ pageNumber }
              block="CategoryPagination"
              elem="ListItem"
            >
                <CategoryPaginationLink
                  isTrackingQueryParam={ isTrackingQueryParam }
                  label={ label }
                  url_path={ pathname }
                  getPage={ onPageSelect }
                  isCurrent={ isCurrent }
                  pageNumber={ pageNumber }
                  getSearchQueryForPage={ getSearchQuery }
                >
                    { children }
                </CategoryPaginationLink>
            </li>
        );
    }
}

export default CategoryPaginationComponent;
