import Loader from 'SourceComponent/Loader';
import { ProductCompareButton as SourceProductCompareButton }
    from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';

/** @namespace AllHomePwafe/Component/ProductCompareButton/Component/ProductCompareButtonComponent */
export class ProductCompareButtonComponent extends SourceProductCompareButton {
    render() {
        const {
            handleClick,
            isLoading,
            isActive,
            mix
        } = this.props;

        return (
        <div
          block="ProductCompareButton"
          mods={ { isActive } }
          mix={ mix }
        >
            <button
              block="ProductCompareButton"
              elem="Button"
              onClick={ handleClick }
              mix={ { block: 'Button' } }
            >
                <div block="ProductCompareButton" elem="Icon" />
                <Loader isLoading={ isLoading } />
                <span>Add to compare</span>
            </button>
        </div>
        );
    }
}

export default ProductCompareButtonComponent;
