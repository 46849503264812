import { InstallPromptAndroid as SourceAnr } from 'SourceComponent/InstallPromptAndroid/InstallPromptAndroid.component';

/** @namespace AllHomePwafe/Component/InstallPromptAndroid/Component/InstallPromptAndroidComponent */
export class InstallPromptAndroidComponent extends SourceAnr {
    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button
              block="InstallPromptAndroid"
              elem="Close"
              onClick={ handleBannerClose }
              aria-label={ __('Close') }
            />
        );
    }

    renderContent() {
        return (
            <p block="InstallPromptAndroid" elem="Content">
                <span block="InstallPrompt" elem="title">Full-Screen Mode</span>
                { __('Add full-screen website in your phone') }
            </p>
        );
    }

    renderInstallButton() {
        const { handleAppInstall } = this.props;

        return (
            <button
              block="InstallPromptAndroid"
              elem="Button"
              mix={ { block: 'Button' } }
              onClick={ handleAppInstall }
            >
                { __('ADD NOW') }
            </button>
        );
    }

    render() {
        return (
            <div block="InstallPromptAndroid">
                { this.renderContent() }
                { this.renderInstallButton() }
                { this.renderCloseButton() }
            </div>
        );
    }
}
export default InstallPromptAndroidComponent;
