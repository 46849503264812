import { FLASH_SALE_STATUSES } from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getDateDifferenceInSeconds, getDateWithTimeZone } from 'Util/Date';

/** @namespace AllHomePwafe/Util/FlashSale/Index/getFlashSalePrice */
export const getFlashSalePrice = (product = {}) => {
    const {
        flash_sale: {
            deal_status,
            product_flash_sale_price
        } = {},
        variants = [],

        price_range: { minimum_price: { final_price: { value: finalPrice = 0 } = {} } = {} } = {}

    } = product;

    // FS sale price will be ignored and returned final price for the main configurable product

    if (variants.length) {
        return finalPrice;
    }

    if (deal_status === FLASH_SALE_STATUSES.RUNNING) {
        return product_flash_sale_price;
    }

    return 0;
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/getFlashSaleRemainingQuantity */
export const getFlashSaleRemainingQuantity = (product = {}) => {
    const {
        flash_sale: {
            deal_qty_left
        } = {}
    } = product;

    return deal_qty_left;
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/isRunningFlashSale */
export const isRunningFlashSale = (product = {}) => {
    const {
        flash_sale: {
            deal_status
        } = {}
    } = product;

    return deal_status === FLASH_SALE_STATUSES.RUNNING;
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/isUpcomingFlashSale */
export const isUpcomingFlashSale = (product = {}) => {
    const {
        flash_sale: {
            deal_status
        } = {}
    } = product;

    return deal_status === FLASH_SALE_STATUSES.UPCOMING;
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/getFlashSaleTimeDiffWithCurrentTime */
export const getFlashSaleTimeDiffWithCurrentTime = ({ flashSale, timeZone }) => {
    const { deal_status, deal_start_at, deal_end_at } = flashSale;
    const dealEndAt = getDateWithTimeZone({ date: deal_end_at || new Date(), timeZone });
    const dealStartAt = getDateWithTimeZone({ date: deal_start_at || new Date(), timeZone });
    const fromDate = deal_status === FLASH_SALE_STATUSES.RUNNING ? new Date() : new Date();
    const toDate = deal_status === FLASH_SALE_STATUSES.RUNNING ? dealEndAt : dealStartAt;

    return getDateDifferenceInSeconds({ fromDate, toDate });
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/getFlashSaleCustomerCartQuantityLimit */
export const getFlashSaleCustomerCartQuantityLimit = () => {
    const {
        storeConfig: { flashsale_limit_each_product_to_each_customer }
    } = BrowserDatabase.getItem(STORE_CONFIG_KEY) || {
        storeConfig: { flashsale_limit_each_product_to_each_customer: Infinity }
    };

    return flashsale_limit_each_product_to_each_customer;
};

/** @namespace AllHomePwafe/Util/FlashSale/Index/isFlashSaleEnabled */
export const isFlashSaleEnabled = () => {
    const {
        storeConfig: { flashsale_active }
    } = BrowserDatabase.getItem(STORE_CONFIG_KEY) || {
        storeConfig: { flashsale_active: false }
    };

    return flashsale_active;
};
