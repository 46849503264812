import {
    getStaticReducers as sourceGetStaticReducers
} from 'SourceStore/index';
import BlogReducer from 'Store/Blog/Blog.reducer';
import CurrentStepReducer from 'Store/CurrentStep/CurrentStep.reducer';
import FlashSaleReducer from 'Store/FlashSale/FlashSale.reducer';
import GoogleMapReducer from 'Store/GoogleMap/GoogleMap.reducer';
import JsonLdReducer from 'Store/JsonLd/JsonLd.reducer';
import LocationChangerReducer from 'Store/LocationChanger/LocationChanger.reducer';
import MenuReducer from 'Store/Menu/Menu.reducer';
import PaymentReducer from 'Store/Payment/Payment.reducer';
import ShopByLookReducer from 'Store/ShopByLook/ShopByLook.reducer';

/**
 * Extended to add LocationChangerReducer
 * @extends
 * @namespace AllHomePwafe/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    LocationChangerReducer,
    CurrentStepReducer,
    BlogReducer,
    JsonLdReducer,
    ShopByLookReducer,
    PaymentReducer,
    FlashSaleReducer,
    GoogleMapReducer,
    MenuReducer
});

export default function injectStaticReducers(store) {
    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => store.injectReducer(name, reducer)
    );

    return store;
}
