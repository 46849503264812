import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';

/** @namespace AllHomePwafe/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    exclusivePricePlpLabel: state.ConfigReducer.exclusiveprice_general_plp_label
});

/** @namespace AllHomePwafe/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    containerProps = () => {
        const { exclusivePricePlpLabel } = this.props;
        return {
            availableVisualOptions: this._getAvailableVisualOptions(),
            currentVariantIndex: this._getCurrentVariantIndex(),
            productOrVariant: this._getProductOrVariant(),
            thumbnail: this._getThumbnail(),
            linkTo: this._getLinkTo(),
            exclusivePricePlpLabel
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
