import { FieldTextarea as SourceFieldTextarea } from 'SourceComponent/FieldTextarea/FieldTextarea.component';

/** @namespace AllHomePwafe/Component/FieldTextarea/Component/FieldTextareaComponent */
export class FieldTextareaComponent extends SourceFieldTextarea {
    render() {
        const {
            id,
            value,
            name,
            rows,
            formRef,
            isDisabled,
            maxLength,
            onChange,
            onFocus,
            onClick,
            placeholder
        } = this.props;

        return (
             <textarea
               ref={ formRef }
               id={ id }
               name={ name }
               rows={ rows }
               value={ value }
               disabled={ isDisabled }
               onChange={ onChange }
               onFocus={ onFocus }
               onClick={ onClick }
               maxLength={ maxLength }
               placeholder={ placeholder }
             />
        );
    }
}

export default FieldTextareaComponent;
