import ConfigQuery from 'Query/Config.query';
import { ConfigDispatcher as SourceConfigDispatcher } from 'SourceStore/Config/Config.dispatcher';

/** @namespace AllHomePwafe/Store/Config/Dispatcher/ConfigDispatcher */
export class ConfigDispatcher extends SourceConfigDispatcher {
    prepareRequest() {
        return [
            ...super.prepareRequest(),
            ConfigQuery.getLabelConfig()
        ];
    }
}

export default new ConfigDispatcher();
