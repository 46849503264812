import { FieldInputContainer as SourceFieldInputContainer } from 'SourceComponent/FieldInput/FieldInput.container';

import FieldInput from './FieldInput.component';

/** @namespace AllHomePwafe/Component/FieldInput/Container/FieldInputContainer */
export class FieldInputContainer extends SourceFieldInputContainer {
    state = {
        togglePasswordActive: false
    };

    containerFunctions = {
        handlePasswordToggle: this.handlePasswordToggle.bind(this)
    };

    handlePasswordToggle() {
        const { togglePasswordActive } = this.state;
        this.setState({
            togglePasswordActive: !togglePasswordActive
        });
    }

    static defaultProps = {
        ...SourceFieldInputContainer.defaultProps,
        enablePasswordToggle: true
    };

    render() {
        const { togglePasswordActive } = this.state;
        return (
            <FieldInput
              { ...this.containerProps() }
              { ...this.containerFunctions }
              togglePasswordActive={ togglePasswordActive }
            />
        );
    }
}

export default FieldInputContainer;
