import { isMobile as sourceIsMobile, isMobileClientHints, isUsingClientHints } from 'SourceUtil/Mobile/isMobile';

/**
 * extends to update mobile max width
 * @extends
 */

export const isMobile = {
    ...sourceIsMobile,
    iOS: () => /iphone|ipod|ipad/i.test(navigator.userAgent),
    any: () => window.matchMedia('(max-width: 1023px)').matches
};

export { isMobileClientHints, isUsingClientHints };
export { default } from 'SourceUtil/Mobile/';
