import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { POPUP, POPUP_NO_HEADER } from 'Component/Header/Header.config';
import {
    mapDispatchToProps, mapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

/** @namespace AllHomePwafe/Component/Popup/Container/PopupContainer */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...this.propTypes,
        isNoHeaderPopup: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isNoHeaderPopup: false
    };

    /**
     * @extends to add @var isNoHeaderPopup to header
     */

    onVisible() {
        const { changeHeaderState, onVisible, isNoHeaderPopup } = this.props;

        const name = isNoHeaderPopup ? POPUP_NO_HEADER : POPUP;

        changeHeaderState({
            name,
            title: this._getPopupTitle(),
            onCloseClick: () => {
                history.back();
            }
        });

        onVisible();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
