import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import Image from 'Component/Image';
import Loader from 'Component/Loader';
import { HOTSPOT_PRODUCT_LOAD_STATUS } from 'Component/ShopByLookProductPopup/ShopByLookProductPopup.config';
import Link from 'SourceComponent/Link';
import { ShopByLookSlide, SlideHotSpot } from 'Type/ShopByLook';
import { getFormattedFinalPrice } from 'Util/Price/Price';

import './ShopByLookProductPopup.style';

/** @namespace AllHomePwafe/Component/ShopByLookProductPopup/Component/ShopByLookProductPopupComponent */
export class ShopByLookProductPopupComponent extends PureComponent {
    static propTypes = {
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        onHoverPopup: PropTypes.func.isRequired,
        onClickPopup: PropTypes.func.isRequired,
        isPopupVisible: PropTypes.bool.isRequired,
        closePopup: PropTypes.func.isRequired,
        hotspot: SlideHotSpot,
        slide: ShopByLookSlide,
        iconImagePath: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        hotspot: {},
        slide: {}
    };

    renderHoverTag() {
        const {
            isPopupVisible, closePopup, isMobile, slide: { slide_id }
        } = this.props;
        const shopByLookImage = document.querySelector(`.ShopByLookImage_slide-${slide_id}`);
        return (
            <Popover
              isOpen={ isPopupVisible }
              parentElement={ shopByLookImage }
              onClickOutside={ closePopup }
              positions={ ['bottom', 'top', 'left', 'right'] } // preferred positions by priority
              content={ this.renderPopup }
            >
                <div block="ShopByLookProductPopup" elem="HoverTag">
                    <div
                      block="ShopByLookProductPopup"
                      elem="Icon"
                      mods={ { isPopupVisible: isPopupVisible && isMobile } }
                    >
                        { this.renderIcon() }
                    </div>
                </div>
            </Popover>
        );
    }

    renderIcon() {
        const { iconImagePath, isPopupVisible, isMobile } = this.props;
        if (!iconImagePath) {
            return null;
        }

        if (isPopupVisible && isMobile) {
            return <Image src="" alt="closeIcon" />;
        }

        return <Image src={ iconImagePath } alt="tag-icon" />;
    }

    renderProduct() {
        const { hotspot: { product } } = this.props;
        if (!product) {
            return null;
        }

        const { url, name, small_image: { url: image_url } } = product;
        const productProps = {
            ...product, price: product.price_range
        };

        return (
            <div block="ShopByLookProductPopup" elem="ProductContainer">
                <div block="ShopByLookProductPopup" elem="ProductImage">
                    <Image src={ image_url } />
                </div>
                <div block="ShopByLookProductPopup" elem="ProductRightContent">
                    <div block="ShopByLookProductPopup" elem="ProductName">
                        { name }
                    </div>
                    <div block="ShopByLookProductPopup" elem="ProductPrice">
                        { getFormattedFinalPrice({ productProps }) }
                    </div>
                    <Link to={ url } block="ShopByLookProductPopup" elem="ShopLink">
                        Shop Now
                    </Link>
                </div>
            </div>

        );
    }

    renderPopupMessage(message) {
        return (
            <div block="ShopByLookProductPopup" elem="PopupMessage">
                { message }
            </div>
        );
    }

    renderPopupContent() {
        const { hotspot: { productLoadState } } = this.props;
        switch (productLoadState) {
        case HOTSPOT_PRODUCT_LOAD_STATUS.LOADING:
            return <Loader isLoading />;
        case HOTSPOT_PRODUCT_LOAD_STATUS.NO_PRODUCT_FROM_SKU:
            return this.renderPopupMessage(__('Product not found'));
        case HOTSPOT_PRODUCT_LOAD_STATUS.NO_SKU:
            return this.renderPopupMessage(__('Product is not configured'));
        case HOTSPOT_PRODUCT_LOAD_STATUS.SUCCESS:
            return this.renderProduct();
        case HOTSPOT_PRODUCT_LOAD_STATUS.ERROR:
        default:
            return this.renderPopupMessage(__('Error in loading product'));
        }
    }

    renderPopup = (renderProps) => {
        const { position, childRect, popoverRect } = renderProps;
        return (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={ position }
              childRect={ childRect }
              popoverRect={ popoverRect }
              arrowColor="white"
              arrowSize={ 6 }
            >
                <div block="ShopByLookProductPopup" elem="Popup">
                    { this.renderPopupContent() }
                </div>
            </ArrowContainer>
        );
    };

    render() {
        const {
            top, left, height, width, onHoverPopup, onClickPopup, closePopup
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              block="ShopByLookProductPopup"
              onMouseOver={ onHoverPopup }
              onMouseOut={ closePopup }
              onClick={ onClickPopup }
              style={ {
                  top, left, height, width
              } }
            >
                { this.renderHoverTag() }
            </div>
        );
    }
}

export default ShopByLookProductPopupComponent;
