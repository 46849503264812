import PropTypes from 'prop-types';

import { Notification as SourceNotification }
    from 'SourceComponent/Notification/Notification.component';

import {
    FILTER_NOTIFICATION_LIST,
    REDIRECT_NOTIFICATION_LIST
} from './Notification.config';

/** @namespace AllHomePwafe/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {
    static propTypes = {
        handleLogout: PropTypes.func.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { notification } = this.props;
        const { msgText } = notification;
        const filterValue = this.checkValue(msgText);
        const filterRedirect = this.checkRedirect(msgText);
        if (filterValue) {
            window.location.reload();
        } else if (filterRedirect) {
            window.location.replace('/checkout/shipping');
        } else {
            super.componentDidMount();
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    checkValue(msgText) {
        const filterValue = FILTER_NOTIFICATION_LIST.some((value) => msgText.toString().includes(value));
        return filterValue;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    checkRedirect(msgText) {
        const filterRedirect = REDIRECT_NOTIFICATION_LIST.some((value) => msgText.toString().includes(value));
        return filterRedirect;
    }
}

export default NotificationComponent;
