import { DATE_TYPE } from 'Component/Field/Field.config';
import FieldDate from 'Component/FieldDate';
import SourceFieldComponent from 'SourceComponent/Field/Field.component';

/** @namespace AllHomePwafe/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceFieldComponent {
    render() {
        const {
            mix,
            type,
            message,
            isDisabled,
            validationStatus
        } = this.props;

        return (
            <div
              block="Field"
              mods={ {
                  type,
                  isDisabled,
                  hasError: validationStatus === false || !!message,
                  isValid: validationStatus === true
              } }
              mix={ mix }
            >
                { this.renderLabel() }
                { this.renderInputOfType(type) }
                { this.renderMessage() }
            </div>
        );
    }

    renderInputOfType(type) {
        if (type === DATE_TYPE) {
            return this.renderDateType();
        }

        return super.renderInputOfType(type);
    }

    renderDateType() {
        return (
            <FieldDate
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              type="text"
            />
        );
    }
}

export default FieldComponent;
