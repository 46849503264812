export * from 'SourceStore/Cart/Cart.action.js';

export const REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM = 'REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM';
export const SET_CART_LOADING_STATE = 'SET_CART_LOADING_STATE';

/** @namespace AllHomePwafe/Store/Cart/Action/removeProductFlashSaleFromCartItem */
export const removeProductFlashSaleFromCartItem = (productId) => ({
    type: REMOVE_PRODUCT_FLASH_SALE_FROM_CART_ITEM,
    productId
});

/** @namespace AllHomePwafe/Store/Cart/Action/setCartLoadingState */
export const setCartLoadingState = (isLoading) => ({
    type: SET_CART_LOADING_STATE,
    isLoading
});
