import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { formatPrice } from 'Util/Price';

/** @namespace AllHomePwafe/Component/ProductPrice/Component/ProductPriceComponent */
export class ProductPriceComponent extends SourceProductPrice {
    renderAdditionalDiscountPrice() {
        const {
            additionalDiscountPrice,
            priceCurrency
        } = this.props;

        if (!additionalDiscountPrice) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="AddtionalDiscountPrice"
              aria-label={ __('Additional Discount Price') }
            >
                { formatPrice(additionalDiscountPrice, priceCurrency) }
            </del>
        );
    }

    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            priceCurrency
        } = this.props;

        if (discountPercentage === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
            >
                { formatPrice(roundedRegularPrice, priceCurrency) }
            </del>
        );
    }

    /**
     * @override to add priceValidUntil Schema
     */

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice
        } = this.props;

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparatment - unsure of resulting type
        // eslint-disable-next-line eqeqeq
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName>
                { formattedFinalPrice }
            </PriceSemanticElementName>
        );
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
            >
                { this.renderCurrentPrice() }
                { this.renderAdditionalDiscountPrice() }
                { this.renderOldPrice() }
                { this.renderSubPrice() }
            </p>
        );
    }
}

export default ProductPriceComponent;
