import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    CategoryPaginationContainer as SourceCategoryPaginationContainer,
    mapDispatchToProps, mapStateToProps
} from 'SourceComponent/CategoryPagination/CategoryPagination.container';

/** @namespace AllHomePwafe/Component/CategoryPagination/Container/CategoryPaginationContainer */
export class CategoryPaginationContainer extends SourceCategoryPaginationContainer {
    static defaultProps = {
        isLoading: false,
        isTrackingQueryParam: true,
        onPageSelect: () => {}
    };

    _getCurrentPage() {
        const { isTrackingQueryParam, currentPage } = this.props;

        if (!isTrackingQueryParam) {
            return currentPage;
        }

        return super._getCurrentPage();
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CategoryPaginationContainer)
);
