import {
    getInitialState as sourceGetInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import {
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_PAY_IN_STORE_LIST,
    UPDATE_PICKUP_STORE_LIST,
    UPDATE_SELECTED_PAY_IN_STORE_ID,
    UPDATE_SELECTED_PICKUP_STORE_ID,
    UPDATE_SELECTED_SHIPPING_METHOD,
    UPDATE_SHIPPING_FIELDS
} from './Checkout.action';

/** @namespace AllHomePwafe/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    pickupStoreList: [],
    payInStoreList: [],
    selectedPickupStoreId: false,
    selectedPayInStoreId: false
});

/** @namespace AllHomePwafe/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;
        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;
        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_PICKUP_STORE_LIST:
        const { pickupStoreList } = action;
        return {
            ...state,
            pickupStoreList
        };

    case UPDATE_PAY_IN_STORE_LIST:
        const { payInStoreList } = action;
        return {
            ...state,
            payInStoreList
        };

    case UPDATE_SELECTED_PICKUP_STORE_ID:
        const { selectedPickupStoreId } = action;
        return {
            ...state,
            selectedPickupStoreId
        };

    case UPDATE_SELECTED_PAY_IN_STORE_ID:
        const { selectedPayInStoreId } = action;
        return {
            ...state,
            selectedPayInStoreId
        };

    case UPDATE_SELECTED_SHIPPING_METHOD:
        const { selectedShippingMethod } = action;
        return {
            ...state,
            selectedShippingMethod
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
