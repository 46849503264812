import {
    lazy,
    Suspense
} from 'react';

import { SearchField as SourceSearchField } from 'SourceComponent/SearchField/SearchField.component';
import { appendWithStoreCode } from 'SourceUtil/Url';
import history from 'Util/History';

// import './SearchField.override.style.scss';

export const SearchOverlay = lazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "category" */
        'SourceComponent/SearchOverlay'
    )
);

/** @namespace AllHomePwafe/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    state = {
        showSearch: true,
        searchType: false
    };

    handleChange = (e) => {
        const { target: { value } } = e;
        const { onSearchBarChange } = this.props;
        onSearchBarChange(e);
        if (value === '') {
            this.closeSearch();
        }
        this.setState({ isPlaceholderVisible: value === '' });
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProp, prevState) {
        const { showSearch } = this.state;

        if (!prevState.showSearch && showSearch) {
            this.onIconClick();
        }
    }

    onSearchEnterPress = (e) => {
        const {
            searchCriteria, hideActiveOverlay, onSearchBarChange, setMenuStatus
        } = this.props;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
            setMenuStatus(false);
        }

        if (e.key !== '') {
            this.setState({ searchType: true });
        }
    };

    closeSearch = () => {
        const { onSearchOutsideClick, onSearchBarChange } = this.props;
        onSearchBarChange({ target: { value: '' } });
        onSearchOutsideClick();
        this.setState({
            showSearch: true,
            searchType: false
        });
    };

    renderClearButton() {
        const { searchType } = this.state;
        if (searchType) {
            return (
                <button
                  block="SearchField"
                  elem="CloseIcon"
                  tabIndex="0"
                  onClick={ this.closeSearch }
                  aria-label={ __('Close') }
                />
            );
        }

        return false;
    }

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive
        } = this.props;

        const { searchType } = this.state;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  placeholder={ __('Enter keyword, item or model number') }
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  autoComplete="off"
                />
                { this.renderClearButton() }
                { !searchType && (
                    <div
                      block="SearchField"
                      elem="SearchIcon"
                      role="button"
                      tabIndex="0"
                    //   onClick={ this.onIconClick }
                      aria-label="2"
                    />
                ) }
                { searchType && (
                    <Suspense fallback={ this.renderOverlayFallback() }>
                        <SearchOverlay
                          isHideOverlay
                          clearSearch={ this.clearSearch }
                          searchCriteria={ searchCriteria }
                        />
                    </Suspense>
                ) }
            </div>
        );
    }

    renderDesktopContent() {
        const { device } = this.props;
        const { showSearch } = this.state;

        if (device.isMobile) {
            return null;
        }

        return (
            <div
              block="SearchField"
              elem="SearchWrapper"
              mods={ { isVisible: showSearch } }
            >
                { this.renderSearch() }
            </div>
        );
    }

    renderMobileContent() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device
        } = this.props;

        if (!device.isMobile) {
            return null;
        }

        const { isPlaceholderVisible, searchType } = this.state;

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                { this.renderClearButton() }
                <input
                  id="search-field"
                  ref={ this.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ onSearchBarFocus }
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive } }
                  autoComplete="off"
                  placeholder="Search"
                />
                { !searchType && (
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  aria-label={ __('Search') }
                />
                ) }
                <div
                  block="SearchField"
                  elem="Placeholder"
                  mods={ {
                      isActive,
                      isPlaceholderVisible
                  } }
                />
                { searchType && (
                <Suspense fallback={ this.renderOverlayFallback() }>
                    <SearchOverlay clearSearch={ this.clearSearch } searchCriteria={ searchCriteria } />
                </Suspense>
                ) }
            </div>
        );
    }
}

export default SearchFieldComponent;
