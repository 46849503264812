import { CATEGORY_STEP } from 'Route/CategoryPage/CategoryPage.config';
import {
    BILLING_STEP, CALLBACK_STEP, DETAILS_STEP, SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { PRODUCT_STEP } from 'Route/ProductPage/ProductPage.config';
import {
    ADDRESS_BOOK,
    DASHBOARD,
    MY_DOWNLOADABLE,
    MY_ORDERS,
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION
} from 'SourceType/Account';

export const NOINDEX_FOLLOW = 'NOINDEX, FOLLOW';
export const NOINDEX_NO_FOLLOW = 'NOINDEX, NOFOLLOW';
export const COMPARE = 'compare';
export const SEARCH = 'catalogsearch';

export const CURRENT_STEP_MAP = {
    [ADDRESS_BOOK]: 'customer',
    [DASHBOARD]: 'customer',
    [MY_DOWNLOADABLE]: 'customer',
    [MY_ORDERS]: 'customer',
    [MY_WISHLIST]: 'wishlist',
    [NEWSLETTER_SUBSCRIPTION]: 'customer',
    [BILLING_STEP]: 'checkout',
    [CALLBACK_STEP]: 'checkout',
    [DETAILS_STEP]: 'checkout',
    [SHIPPING_STEP]: 'checkout',
    [COMPARE]: COMPARE,
    [SEARCH]: SEARCH,
    [CATEGORY_STEP]: 'catalog_category_view',
    [PRODUCT_STEP]: 'catalog_product_view'
};
