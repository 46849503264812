import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { POPUP } from 'Component/Header/Header.config';
import MyAccountDeleteAccountPopupComponent
    from 'Component/MyAccountDeleteAccountPopup/MyAccountDeleteAccountPopup.component';
import {
    MY_ACCOUNT_DELETE_PROFILE_POPUP_ID,
    MY_ACCOUNT_DELETE_PROFILE_POPUP_TITLE, MY_ACCOUNT_DELETE_PROFILE_POPUP_TITLE_MOBILE
} from 'Component/MyAccountDeleteAccountPopup/MyAccountDeleteAccountPopup.config';
import { changeNavigationHeaderTitle, changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { customerType } from 'Type/Account';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace AllHomePwafe/Component/MyAccountDeleteAccountPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    firstName: state.MyAccountReducer.customer.firstname,
    lastName: state.MyAccountReducer.customer.lastname,
    isPopupActive: state.PopupReducer.popupPayload
        && state.PopupReducer.popupPayload[MY_ACCOUNT_DELETE_PROFILE_POPUP_ID]
        && state.PopupReducer.popupPayload[MY_ACCOUNT_DELETE_PROFILE_POPUP_ID].title
});

/** @namespace AllHomePwafe/Component/MyAccountDeleteAccountPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    changeNavigationHeaderTitle: (title) => dispatch(changeNavigationHeaderTitle(title)),
    setHeaderState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    deleteCustomerAccount: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.deleteAccount(dispatch)
    )
});

/** @namespace AllHomePwafe/Component/MyAccountDeleteAccountPopup/Container/MyAccountDeleteAccountPopupContainer */
export class MyAccountDeleteAccountPopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        isPopupActive: PropTypes.bool.isRequired,
        changeNavigationHeaderTitle: PropTypes.func.isRequired,
        showEditUserPopup: PropTypes.func.isRequired,
        customer: customerType.isRequired,
        isMobile: PropTypes.bool.isRequired,
        setHeaderState: PropTypes.func.isRequired,
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        deleteCustomerAccount: PropTypes.func.isRequired
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        closePopup: this.closePopup.bind(this),
        deleteAccount: this.deleteAccount.bind(this)
    };

    componentDidUpdate() {
        const {
            changeNavigationHeaderTitle, isPopupActive, setHeaderState, isMobile
        } = this.props;

        if (isPopupActive) {
            const title = isMobile
                ? MY_ACCOUNT_DELETE_PROFILE_POPUP_TITLE_MOBILE : MY_ACCOUNT_DELETE_PROFILE_POPUP_TITLE;

            setHeaderState({ title, name: POPUP });
            changeNavigationHeaderTitle(title);
        }
    }

    closePopup() {
        const { hideActiveOverlay } = this.props;
        hideActiveOverlay();
    }

    async deleteAccount() {
        const { deleteCustomerAccount } = this.props;
        try {
            this.setState({ isLoading: true });
            await deleteCustomerAccount();
            const { hideActiveOverlay } = this.props;
            hideActiveOverlay();
        } catch (e) {
            console.error(e);
        }

        this.setState({ isLoading: false });
    }

    render() {
        return (
            <MyAccountDeleteAccountPopupComponent
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.containerFunctions }
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDeleteAccountPopupContainer);
