import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import { ProductCompareAddSuccessPopupComponent }
    from './ProductCompareAddSuccessPopup.component';
import { PRODUCT_COMPARE_ADD_SUCCESS }
    from './ProductCompareAddSuccessPopup.config';

/** @namespace AllHomePwafe/Component/ProductCompareAddSuccessPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    name: state.PopupReducer.popupPayload[PRODUCT_COMPARE_ADD_SUCCESS]?.name,
    small_image: state.PopupReducer.popupPayload[PRODUCT_COMPARE_ADD_SUCCESS]?.small_image
});

/** @namespace AllHomePwafe/Component/ProductCompareAddSuccessPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCompareAddSuccessPopupComponent);
