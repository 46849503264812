export const FLASH_TIMER_MODES = {
    PDP: 'PDP',
    CART_PAGE: 'CartPage',
    PLP: 'PLP',
    CHECKOUT_CART_ITEM: 'CHECKOUT_CART_ITEM'
};
export const TIME_UNITS = {
    SECONDS: 'seconds',
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAY: 'days'
};

export const FLASH_SALE_STATUSES = {
    RUNNING: 'Running',
    UPCOMING: 'Upcoming'
};

export const FLASH_SALE_DISCOUNT_TYPES = {
    PERCENTAGE: 'percent'
};

export const TIME_UNIT_DISPLAY_DATA = [
    {
        type: TIME_UNITS.DAY,
        title: __('Days'),
        showColon: true
    },
    {
        type: TIME_UNITS.HOURS,
        title: __('Hours'),
        showColon: true
    },
    {
        type: TIME_UNITS.MINUTES,
        title: __('Mins'),
        showColon: true
    },
    {
        type: TIME_UNITS.SECONDS,
        title: __('Secs'),
        showColon: false
    }
];

export const FLASH_UPDATE_TIME_INTERVAL = 1000;
export const FLASH_SALE_PROGRESS_COLORS = {
    RED: 'red',
    ORANGE: 'orange',
    GREEN: 'green'
};

export const PERCENTAGE_33 = 33.33;
export const PERCENTAGE_66 = 66.66;
export const CART_REFRESH_FOR_FLASH_SALE_WAIT_TIME = 300;
