import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
/** @namespace AllHomePwafe/Component/CarouselButtonGroup/Component/CarouselButtonGroupComponent */
export class CarouselButtonGroupComponent extends PureComponent {
    static propTypes = {
        block: PropTypes.string.isRequired,
        next: PropTypes.func.isRequired,
        previous: PropTypes.func.isRequired
    };

    renderButtonGroup() {
        const {
            block, next, previous, ...rest
        } = this.props;
        const { carouselState: { currentSlide, totalItems, slidesToShow } } = rest;
        const lastSlide = totalItems - slidesToShow;
        const isHidden = slidesToShow >= totalItems;

        const renderButton = (btnText, clickEvent, isDisabled) => (
            <button
              block={ block }
              elem={ `SliderButton${btnText}` }
              mix={ { block: 'ProductListPage', elem: 'SliderButtons' } }
              onClick={ clickEvent }
              aria-label={ btnText }
              mods={ { isDisabled, isHidden } }
            >
                <span>{ btnText }</span>
            </button>
        );

        return (
            <div block={ block } elem="SliderArrows">
                { renderButton('Prev', previous, currentSlide === 0) }
                { renderButton('Next', next, lastSlide < 0 || currentSlide === lastSlide) }
            </div>
        );
    }

    render() {
        return this.renderButtonGroup();
    }
}

export default CarouselButtonGroupComponent;
