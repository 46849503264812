import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/NotificationList/NotificationList.container';

import NotificationList from './NotificationList.component';

/** @namespace AllHomePwafe/Component/NotificationList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentStep: state.CurrentStepReducer.step
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
