import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { getGuestQuoteId } from 'Util/Cart';
import { getFormattedDateStringYYYYMMDD } from 'Util/Date';
import { Field } from 'Util/Query';

/** @namespace AllHomePwafe/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    /**
     * @extends to add @function _getCustomAddressesField to @function _getAddressesField
     */

    _getAddressesField() {
        return new Field('addresses')
            .addFieldList([...this._getAddressFields(), ...this._getCustomAddressesField()]);
    }

    getCustomerIdQuery() {
        return new Field('km_fetchCustomer')
            .addField(new Field('customer_id'));
    }

    getUpdateInformationMutation(options) {
        const customerData = {
            ...options,
            date_of_birth: getFormattedDateStringYYYYMMDD(options.date_of_birth)
        };

        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', customerData)
            .addField(this._getCustomerField());
    }

    getCreateAccountMutation(options) {
        const { customer, password } = options;

        const customerData = {
            ...customer,
            password,
            date_of_birth: getFormattedDateStringYYYYMMDD(customer.date_of_birth)
        };

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', customerData)
            .addField(this._getCustomerField());
    }

    _getCustomAddressesField() {
        return [
            'city_id',
            'district',
            'district_id'
        ];
    }

    getCreateAddressMutation(options) {
        // Remove logistic_store_id from query;
        // eslint-disable-next-line no-unused-vars
        const { logistic_store_id, ...customerAddress } = options;
        return new Field('createCustomerAddress')
            .addArgument('input', 'CustomerAddressInput!', customerAddress)
            .addFieldList(this._getAddressFields());
    }

    _getCustomerFields() {
        return [
            'created_at',
            'confirmation_required',
            'group_id',
            'prefix',
            'firstname',
            'middlename',
            'lastname',
            'suffix',
            'email',
            'default_billing',
            'default_shipping',
            'dob',
            'taxvat',
            'id',
            'is_subscribed',
            'gender',
            'date_of_birth',
            this._getAddressesField()
        ];
    }

    getAllRewardValidation(cardNumber) {
        return new Field('km_validateAllRewardNo')
            .addArgument('guestCartId', 'String!', '')
            .addArgument('cardNo', 'String!', cardNumber)
            .addFieldList(['card_no', 'is_valid']);
    }

    getDeleteAccountMutation() {
        return new Field('deleteCustomer')
            .addArgument('action', 'String!', 'delete');
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('')
            // eslint-disable-next-line no-magic-numbers
            .map((c) => `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

        return JSON.parse(jsonPayload);
    }

    getSocialSignInMutation(type, options) {
        const {
            accessCode, accessToken, email = '', first_name = '', last_name = ''
        } = options;

        function getFieldType(type) {
            switch (type) {
            case 'facebook': return 'generateCustomerTokenFacebook';
            case 'google': return 'generateCustomerTokenGoogle';
            case 'apple': return 'generateCustomerTokenApple';
            default: return 'generateCustomerTokenFb';
            }
        }

        const fieldType = new Field(getFieldType(type));
        const guestQuoteId = getGuestQuoteId();

        fieldType.addField('token')
            .addArgument('guest_quote_id', 'String', guestQuoteId);

        if (type === 'google') {
            fieldType.addArgument('accessCode', 'String!', accessCode);
        } else if (type === 'apple') {
            const { email } = this.parseJwt(accessToken);
            fieldType.addArgument('accessToken', 'String!', accessToken)
                .addArgument('email', 'String!', email);
        } else {
            fieldType.addArgument('accessToken', 'String!', accessToken)
                .addArgument('email', 'String!', email)
                .addArgument('firstname', 'String!', first_name)
                .addArgument('lastname', 'String!', last_name);
        }

        return fieldType;
    }
}

export default new MyAccountQuery();
