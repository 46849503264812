import {
    PASSWORD_TYPE,
    TEXT_TYPE
} from 'Component/Field/Field.config';
import { FieldInput as SourceFieldInput } from 'SourceComponent/FieldInput/FieldInput.component';

/** @namespace AllHomePwafe/Component/FieldInput/Component/FieldInputComponent */
export class FieldInputComponent extends SourceFieldInput {
    renderTogglePasswordButton(type, enablePasswordToggle) {
        const { handlePasswordToggle } = this.props;

        return type === PASSWORD_TYPE && enablePasswordToggle
            ? (
                <button
                  type="button"
                  onClick={ handlePasswordToggle }
                  block="FieldInput"
                  elem="TogglePassword"
                >
                    <span>{ __('Toggle') }</span>
                </button>
            ) : false;
    }

    render() {
        const {
            formRef,
            enablePasswordToggle,
            ...validProps
        } = this.props;

        const {
            type
        } = this.props;

        return enablePasswordToggle && type === PASSWORD_TYPE ? this.renderPasswordToggleInput() : (
            <input
              ref={ formRef }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...validProps }
            />
        );
    }

    renderPasswordToggleInput() {
        const {
            formRef,
            enablePasswordToggle,
            togglePasswordActive,
            ...validProps
        } = this.props;

        const { type: initialType } = this.props;

        const type = enablePasswordToggle && togglePasswordActive ? TEXT_TYPE : initialType;

        return (
            <div block="FieldInput" elem="Password" mods={ { togglePasswordActive } }>
                <input
                  ref={ formRef }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...validProps }
                  type={ type }
                />
                { this.renderTogglePasswordButton(initialType, enablePasswordToggle) }
            </div>
        );
    }
}

export default FieldInputComponent;
