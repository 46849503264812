import { PureComponent } from 'react';

import ARLinkComponent from './ARLink.component';

/** @namespace AllHomePwafe/Component/ARLink/Container/ARLinkContainer */
export class ARLinkContainer extends PureComponent {
    containerFunctions = {
        onARLinkClick: this.onARLinkClick.bind(this)
    };

    onARLinkClick(event) {
        event.stopPropagation();
    }

    render() {
        return (
            <ARLinkComponent
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default ARLinkContainer;
