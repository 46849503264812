import { UPDATE_INSTALLMENT_PRICE_STATE } from './Payment.action';

/** @namespace AllHomePwafe/Store/Payment/Reducer/getInitialState */
export const getInitialState = () => ({
    isInstallmentPrice: false
});

/** @namespace AllHomePwafe/Store/Payment/Reducer/PaymentReducer */
export const PaymentReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_INSTALLMENT_PRICE_STATE:
        const { isInstallmentPrice } = action;
        return { ...state, isInstallmentPrice };

    default:
        return state;
    }
};

export default PaymentReducer;
