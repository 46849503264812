export * from 'SourceComponent/ProductPrice/ProductPrice.config';
export const PERCENT = 'PERCENT';
export const PERCENT_STATIC = 100;
export const PRICE_TYPES = {
    REGULAR_PRICE: 'REGULAR_PRICE',
    FINAL_PRICE: 'FINAL_PRICE'
};
export const OPTION_PRICE_TYPE = {
    FIXED: 'fixed',
    PERCENTAGE: 'percent'
};
