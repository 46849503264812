import { CHECKBOX_TYPE } from 'Component/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace AllHomePwafe/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    onChange(event) {
        if (typeof event === 'string' || typeof event === 'number') {
            return this.handleChange(event);
        }

        if (event.currentTarget && event.currentTarget.value.length <= 0) {
            this.setState({
                validationStatus: null
            });
        }

        const validationRule = this.validateField();

        this.setState({
            validationStatus: !validationRule.validate,
            validationMessage: validationRule.message
        });

        return this.handleChange(event.target ? event.target.value : event);
    }

    componentDidUpdate(prevProps) {
        const { value: prevValue, checked: prevChecked } = prevProps;
        const { value: currentValue, checked: currChecked, type } = this.props;

        if (prevValue !== currentValue) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: currentValue });
        }
        if (type === CHECKBOX_TYPE && currChecked !== prevChecked) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ checked: currChecked });
        }

        if ((!this.props.formRef || !this.props.formRef.current)
            // if the form field is not there
            // in the case of the fields which are used outside the forms
            || this.props.formRef.current.isFormSubmitAttempted) {
            // in the case of the fiels are used inside the forms check if the form is submitted
            this.updateValidationStatus();
            this.setValidationMessage(prevProps);
        }
    }
}

export default FieldContainer;
