import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { isFlashSaleEnabled } from 'Util/FlashSale';
import { Field } from 'Util/Query';

/** @namespace AllHomePwafe/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    /**
     * @extends to change getCartForCustomer into km_getCartForCustomer
     */

    getQuoteIdQuery() {
        const query = new Field('customerCart')
            .addFieldList(this._getQuoteIdQueryFields());

        return query;
    }

    _getQuoteIdQueryFields() {
        return ['id'];
    }

    getCartQuery(quoteId) {
        const query = new Field('km_getCartForCustomer')
            .addFieldList(this._getCartTotalsFields())
            .setAlias('cartData');

        if (!isSignedIn()) {
            query.addArgument('guestCartId', 'String', quoteId);
        }

        return query;
    }

    _getCouponCodesField() {
        return new Field('coupon_codes')
            .addFieldList(['key', 'code']);
    }

    _getMCouponCodesField() {
        return new Field('multiple_coupon_data')
            .addFieldList(['label', 'amount']);
    }

    /**
     * @extends to add additional fee
     */

    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            this._getCouponCodesField(),
            this.getAppliedGiftCardField(),
            this._getMCouponCodesField(),
            'additional_fee',
            'additional_fee_title',
            'zero_error_message',
            'eligible_for_exclusive'
        ];
    }

    getRemoveCouponMutation(quoteId, couponCode) {
        const mutation = new Field('removeCoupon')
            .addField(this.getCartQuery(quoteId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }
        mutation.addArgument('couponCode', 'String', couponCode);

        return mutation;
    }

    getCartID() {
        const query = new Field('customerCart')
            .addField(new Field('id'));

        return query;
    }

    getApplyGiftCouponCartField() {
        return new Field('cart')
            .addField(this.getAppliedGiftCardField());
    }

    getAppliedBalanceGiftCardField() {
        return new Field('applied_balance')
            .addFieldList(['value', 'currency']);
    }

    getCurrentBalanceGiftCardField() {
        return new Field('current_balance')
            .addFieldList(['value', 'currency']);
    }

    getAppliedGiftCardField() {
        return new Field('applied_gift_cards')
            .addFieldList([
                this.getAppliedBalanceGiftCardField(),
                'code',
                this.getCurrentBalanceGiftCardField(),
                'expiration_date'
            ]);
    }

    getApplyGiftCouponMutation({ giftCardCode, cartId }) {
        const mutation = new Field('applyGiftCardToCart')
            .addArgument('input', 'ApplyGiftCardToCartInput', {
                cart_id: cartId,
                gift_card_code: giftCardCode
            })
            .addField(this.getApplyGiftCouponCartField());

        return mutation;
    }

    getRemoveGiftCouponMutation({ cartId, giftCardCode }) {
        const mutation = new Field('removeGiftCardFromCart')
            .addArgument('input', 'RemoveGiftCardFromCartInput', {
                cart_id: cartId,
                gift_card_code: giftCardCode
            })
            .addField(this.getApplyGiftCouponCartField());

        return mutation;
    }

    _getProductField() {
        const product = super._getProductField();
        if (isFlashSaleEnabled()) {
            product.addField(this._getFlashSaleField());
        }

        return product;
    }

    _getFlashSaleField() {
        return new Field('flash_sale')
            .addFieldList([
                'deal_status',
                'deal_start_at',
                'deal_end_at',
                'deal_qty_left'
            ]);
    }

    clearCartSessionVariablesQuery() {
        return new Field('clearCartSessionVariables');
    }
}

export default new CartQuery();
