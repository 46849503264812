/* eslint-disable max-lines */
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { isFlashSaleEnabled } from 'Util/FlashSale';
import { Field, Fragment } from 'Util/Query';

/** @namespace AllHomePwafe/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            isCategoryList = false
        } = this.options;

        const fields = [
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'installment_label',
            'has_options',
            'product_seo_name',
            'meta_robots',
            this._getCategoriesField(), // Change for GTM
            this._getPriceRangeField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getShortDescriptionField(),
            'special_from_date',
            'special_to_date',
            this._getTierPricesField(),
            !isCategoryList ? this._getMageWorxSeoMarkupField() : false, // Prevent fetching snippets in category page
            !isCategoryList ? this._getMageWorxHreflangsField() : false, // Prevent fetching snippets in category page
            'additional_discount',
            'exclusive_price'
        ];

        if (isFlashSaleEnabled()) {
            fields.push(this._getFlashSaleField());
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getReviewCountField(),
                this._getRatingSummaryField()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment(),
                this._getMageWorxCanonicalUrlField()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }

    _getItemsField() {
        const items = super._getItemsField();
        if (isFlashSaleEnabled()) {
            items.addField(this._getFlashSaleField());
        }

        return items;
    }

    _getFlashSaleField() {
        return new Field('flash_sale')
            .addFieldList([
                'deal_status',
                'deal_start_at',
                'deal_end_at',
                'deal_qty_left',
                'product_deal_qty',
                'product_flash_sale_price'
            ]);
    }

    _getAttributeFields() {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_type',
            'attribute_label',
            this._getAttributeOptionsField()
        ];
    }

    _getAggregationsFields() {
        return [
            new Field('label').setAlias('name'),
            new Field('attribute_code').setAlias('request_var'),
            'position',
            this._getAggregationsOptionsField()
        ];
    }

    _getOptionField() {
        return new Field('option_prices')
            .addFieldList([
                'text',
                'currency',
                'final_price',
                'regular_price',
                'discounted_price',
                'price_type',
                'price_diff',
                'additional_discount'
            ]);
    }

    _getMinimalPriceFields() {
        return [
            this._getDiscountField(),
            this._getFinalPriceField(),
            this._getFinalPriceExclTaxField(),
            this._getRegularPriceField(),
            this._getRegularPriceExclTaxField(),
            this._getOptionField(),
            this._getAdditionalDiscountPriceField(),
            this._getOptionField(),
            this._getExclusivePriceField(),
            this._getExclusiveSavingPriceField(),
            'exclusive_percentage'
        ];
    }

    _getMageWorxCanonicalUrlField() {
        return new Field('mw_canonical_url')
            .addFieldList(this._getMageWorxCanonicalUrlFields());
    }

    _getMageWorxCanonicalUrlFields() {
        return ['url'];
    }

    _getMageWorxSeoMarkupField() {
        return new Field('mw_seo_markup')
            .addField(this._getMageWorxSeoRichSnippetField());
    }

    _getMageWorxSeoRichSnippetField() {
        return new Field('rich_snippets')
            .addFieldList(this._getMageWorxSeoRichSnippetFields());
    }

    _getMageWorxSeoRichSnippetFields() {
        return ['product', 'seller', 'website'];
    }

    _getMageWorxHreflangsField() {
        return new Field('mw_hreflangs')
            .addField(this._getMageWorxHreflangItems());
    }

    _getMageWorxHreflangItems() {
        return new Field('items')
            .addFieldList(this._getMageWorxHreflangItemFields());
    }

    _getMageWorxHreflangItemFields() {
        return ['url', 'code'];
    }

    getProductViewReportQuery(id) {
        return new Field('productViewReport')
            .addArgument('input', 'ViewProductInput!', {
                product_id: id
            });
    }

    /**
     * @override to remove _getMaximalPriceField
     */

    _getPriceRangeFields() {
        return [
            this._getMinimalPriceField()
        ];
    }

    _getCategoryTreeFields() {
        return [
            'is_active'
        ];
    }

    _getCategoryTree() {
        return new Fragment('CategoryTree')
            .addFieldList(this._getCategoryTreeFields());
    }

    _getCategoryFields() {
        return [
            ...super._getCategoryFields(),
            this._getCategoryTree()
        ];
    }

    _getAdditionalDiscountPriceField() {
        return new Field('second_strikethrough_price')
            .addField('currency')
            .addField('value');
    }

    _getExclusiveSavingPriceField() {
        return new Field('exclusive_saving')
            .addField('currency')
            .addField('value');
    }

    _getExclusivePriceField() {
        return new Field('exclusive_price_value')
            .addField('currency')
            .addField('value');
    }
}

export default new ProductListQuery();
