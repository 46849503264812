import { IMAGE_LOADED, IMAGE_LOADING } from 'Component/Image/Image.config';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

/** @namespace AllHomePwafe/Component/Image/Component/ImageComponent */
export class ImageComponent extends SourceImage {
    static defaultProps = {
        ...this.defaultProps,
        lazyLoad: true
    };

    renderImage() {
        const {
            alt,
            src,
            style,
            title,
            lazyLoad
        } = this.props;
        const { imageStatus } = this.state;

        if (imageStatus === IMAGE_LOADING || imageStatus === IMAGE_LOADED) {
            return (
                <img
                  block="Image"
                  elem="Image"
                  src={ src || '' }
                  alt={ alt }
                  mods={ { isLoading: imageStatus === IMAGE_LOADING } }
                  style={ style }
                  title={ title }
                  onLoad={ this.onLoad }
                  onError={ this.onError }
                  loading={ lazyLoad ? 'lazy' : false }
                />
            );
        }

        return super.renderImage();
    }
}

export default ImageComponent;
