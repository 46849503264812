/* eslint-disable max-lines */
import { cloneElement } from 'react';

import { Form as SourceForm } from 'SourceComponent/Form/Form.component';

/** @namespace AllHomePwafe/Component/Form/Component/FormComponent */
export class FormComponent extends SourceForm {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    static cloneAndValidateChildren(propsChildren, refMap, isFormSubmitAttempted) {
        const invalidFields = [];
        const children = FormComponent.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { id, name } } = child;
                const { message } = FormComponent.validateField(child, refMap);
                if (message) {
                    invalidFields.push(id);
                    // Set the flag for the form submission to check the validation from the form component.
                    // eslint-disable-next-line no-param-reassign
                    refMap[name].current.isFormSubmitAttempted = isFormSubmitAttempted;
                    return cloneElement(child, {
                        message,
                        formRef: refMap[name]
                    });
                }

                return cloneElement(child, {
                    formRef: refMap[name]
                });
            }
        );

        return { children, fieldsAreValid: !invalidFields.length, invalidFields };
    }

    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        e.preventDefault();
        onSubmit();

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
            // Pass on the data for the form submitted value
        }, this.collectFieldsInformation(true));

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        asyncData.then(
            /** @namespace AllHomePwafe/Component/Form/Component/then */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    onSubmitSuccess(inputValues, asyncDataList);
                    return;
                }

                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace AllHomePwafe/Component/Form/Component/then */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };

    // Received the isFormSubmitAttempted value and pass to cloneAndValidateChildren method
    // to check the field validation to be fired only on form submission.
    // eslint-disable-next-line max-lines
    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields
        } = FormComponent.cloneAndValidateChildren(propsChildren, refMap, true);

        this.setState({ children, fieldsAreValid });

        const inputValues = Object.values(refMap).reduce((inputValues, input) => {
            const { current } = input;
            if (current && current.id && current.value) {
                const { name, value, checked } = current;

                if (current.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (current.type === 'checkbox') {
                    const boolValue = checked;
                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        if (invalidFields.length) {
            const { current } = refMap[invalidFields[0]];
            if (current.scrollIntoView) {
                current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            }
        }

        return {
            inputValues,
            invalidFields
        };
    };
}

export default FormComponent;
