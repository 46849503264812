import '/home/magento/codepool/public_html/all-home-pwa-fe/node_modules/@scandipwa/scandipwa-extensibility/runtime-helpers';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Style/main';

// noinspection JSConstantReassignment
PropTypes.string = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
]);

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
