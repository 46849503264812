import { connect } from 'react-redux';

import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import history from 'Util/History';

/** @namespace AllHomePwafe/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    zeroErrorMessage: state.CartReducer.cartTotals?.zero_error_message
});

/** @namespace AllHomePwafe/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    /**
     * extended to preventDefault event
     * @param {object} event click event
     * @extends
     */

    buttonClick(event) {
        event.preventDefault();
        const { product } = this.props;

        if (!this.redirectIfRequired(product)) {
            super.buttonClick(event);
        }
    }

    /**
     * Redirect to product page if options needs to be selected
     * @param {Object} product object
     */

    redirectIfRequired(product) {
        const { options = [], url } = product;
        const needsRedirect = url !== location.pathname && options.length;
        if (needsRedirect) {
            history.push({ pathname: url });
        }

        return needsRedirect;
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault,
            zeroErrorMessage,
            product: { name } = {}
        } = this.props;

        if (zeroErrorMessage) {
            showNotification('Error', __('%s cannot be added to cart.', name));
        } else {
            showNotification('success-added-to-cart', __('Added to cart'));
        }

        setQuantityToDefault();

        this.removeProductFromWishlist();
        this.setState({ isLoading: false });
    }

    addGroupedProductToCart() {
        const {
            product,
            currencyCode,
            product: { items },
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                currencyCode,
                quantity
            });
        })).then(
            /** @namespace AllHomePwafe/Component/AddToCart/Container/all/then */
            () => this.afterAddToCart(),
            /** @namespace AllHomePwafe/Component/AddToCart/Container/all/then */
            () => this.resetLoading()
        );
    }

    addConfigurableProductToCart() {
        const {
            product,
            quantity,
            currencyCode,
            addProduct,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            currencyCode,
            quantity,
            productOptionsData
        }).then(
            /** @namespace AllHomePwafe/Component/AddToCart/Container/addProduct/then */
            () => this.afterAddToCart(),
            /** @namespace AllHomePwafe/Component/AddToCart/Container/addProduct/then */
            () => this.resetLoading()
        );
    }

    addSimpleProductToCart() {
        const {
            product,
            quantity,
            addProduct,
            currencyCode,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            currencyCode,
            productOptionsData
        }).then(
            /** @namespace AllHomePwafe/Component/AddToCart/Container/addProduct/then */
            () => this.afterAddToCart(),
            /** @namespace AllHomePwafe/Component/AddToCart/Container/addProduct/then */
            () => this.resetLoading()
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
