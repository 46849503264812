export * from 'SourceUtil/Cart/Cart.js';

/** @namespace AllHomePwafe/Util/Cart/hasMinimumAmountError */
export const hasMinimumAmountError = (cartProps) => {
    const {
        minimumOrderAmount,
        minimumOrderEnabled,
        totals: { subtotal, items, grand_total },
        minimumIncludeDiscountAmount
    } = cartProps;

    const total = minimumIncludeDiscountAmount ? grand_total : subtotal;

    return minimumOrderEnabled && total < minimumOrderAmount && items.length;
};

/** @namespace AllHomePwafe/Util/Cart/getZeroPricedProductNames */
export const getZeroPricedProductNames = (items, includeErrorMessage = false) => {
    const productNames = items
        .reduce((result, { price, product: { name } }) => (!price ? [...result, name] : result), [])
        .join(',');

    if (!productNames) {
        return false;
    }

    if (includeErrorMessage) {
        return __(`Unable to proceed to checkout with %s
        product(s) in your cart. Remove them before proceeding.`, productNames);
    }

    return productNames;
};

/** @namespace AllHomePwafe/Util/Cart/hasExclusivePricedProducts */
export const hasExclusivePricedProducts = (items) => items.some(({ sku, product }) => {
    const currentProduct = sku === product.sku ? product : product.variants
        .find(({ sku: variantSku }) => variantSku === sku);

    return currentProduct?.attributes?.exclusive_price?.attribute_value;
});
