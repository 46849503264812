import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import history from 'Util/History';

import { PayInStoreDisclaimerPopupComponent } from './PayInStoreDisclaimerPopup.component';
import { PAY_IN_STORE_DISCLAIMER_POPUP_ID } from './PayInStoreDisclaimerPopup.config';

/** @namespace AllHomePwafe/Component/PayInStoreDisclaimerPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    disclaimerMessage: state.ConfigReducer.disclaimer_message,
    disclaimerMessageTitle: state.ConfigReducer.disclaimer_message_header,
    isMobile: state.ConfigReducer.device?.isMobile,
    activeOverlay: state.OverlayReducer?.activeOverlay
});

/** @namespace AllHomePwafe/Component/PayInStoreDisclaimerPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = () => {};

/** @namespace AllHomePwafe/Component/PayInStoreDisclaimerPopup/Container/PayInStoreDisclaimerPopupContainer */
export class PayInStoreDisclaimerPopupContainer extends PureComponent {
    static propTypes = {
        activeOverlay: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    containerFunctions = {
        handleGoBack: this.handleGoBack.bind(this)
    };

    handleGoBack() {
        const { isMobile, activeOverlay } = this.props;

        if (!isMobile || activeOverlay === PAY_IN_STORE_DISCLAIMER_POPUP_ID) {
            history.goBack();
        }
    }

    render() {
        return (
            <PayInStoreDisclaimerPopupComponent
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayInStoreDisclaimerPopupContainer);
