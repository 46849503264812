import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';

export const MenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Menu/Menu.dispatcher'
);

/** @namespace AllHomePwafe/Component/MenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMenuStatus: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setMenuStatus(dispatch, options)
    )
});

/** @namespace AllHomePwafe/Component/MenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace AllHomePwafe/Component/MenuItem/Container/MenuItemContainer */
export class MenuItemContainer extends SourceMenuItemContainer {
    onItemClick() {
        const { setMenuStatus } = this.props;
        super.onItemClick();
        setMenuStatus();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
