import { getInitialState, MyAccountReducer as sourceMyAccountReducer } from 'SourceStore/MyAccount/MyAccount.reducer';
import { UPDATE_CUSTOMER_DETAILS } from 'Store/MyAccount/MyAccount.action';
import { onUpdateCustomerDetailsGTMEvent } from 'Util/GTM/GTM';

/** @namespace AllHomePwafe/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const { customer } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_DETAILS:
        onUpdateCustomerDetailsGTMEvent(customer);
        return {
            ...state,
            customer
        };

    default:
        return sourceMyAccountReducer(state, action);
    }
};

export default MyAccountReducer;
