import {
    CHECKOUT_URL,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY
} from 'SourceRoute/Checkout/Checkout.config';

export const SHIPPING_STEP = 'isShipingStep';
export const BILLING_STEP = 'isBillingStep';
export const DETAILS_STEP = 'isSuccessStep';
export const CALLBACK_STEP = 'isCallbackStep';

export const LAST_REDIRECT_ORDER = 'lastRedirectedOrderId';
export const GUEST_QUOTE_id = 'guest_quote_id';
export const ORDER_TYPE_PLACED = 'orderPlaced';
export const ORDER_TYPE_PENDING = 'orderPending';
export const ERROR_MESSAGE_FOR_INVALID_ITEMS_POPUP = 'Please select a different payment method';

export {
    CHECKOUT_URL,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY
};
