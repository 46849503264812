import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps, mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer
} from 'SourceComponent/ProductList/ProductList.container';
import { openProductListGTMEVent } from 'Util/GTM/GTM';

/** @namespace AllHomePwafe/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    pageSize: state.ConfigReducer.grid_per_page,
    listTitle: state.BreadcrumbsReducer.breadcrumbs && state.BreadcrumbsReducer.breadcrumbs[0]
        && state.BreadcrumbsReducer.breadcrumbs[0].name
});
/** @namespace AllHomePwafe/Component/ProductList/Container/ProductListContainer */
export class ProductListContainer extends SourceProductListContainer {
    // overridded to remove pageSize from default props
    static defaultProps = {
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: true,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false
    };

    onProductListSuccess = ({ productData }) => {
        openProductListGTMEVent({
            products: productData?.products?.items || [],
            listTitle: this.props.listTitle
        });
    };

   // overrided to request with correct pageSize value depending on isWidget
   requestPage = (currentPage = 1, isNext = false) => {
       const {
           sort,
           search,
           filter,
           requestProductList,
           requestProductListInfo,
           noAttributes,
           noVariants,
           isWidget,
           pageSize,
           productsCount
       } = this.props;

       /**
     * In case the wrong category was passed down to the product list,
     * prevent it from being requested.
     */
       if (filter.categoryIds === -1) {
           return;
       }

       /**
     * Do not request page if there are no filters
     */
       if (!search && !this.isEmptyFilter()) {
           return;
       }

       // TODO: product list requests filters alongside the page
       // TODO: sometimes product list is requested more then once
       // TODO: the product list should not request itself, when coming from PDP
       const correctPageSize = isWidget ? productsCount : pageSize;
       const options = {
           isNext,
           noAttributes,
           noVariants,
           onSuccess: this.onProductListSuccess,
           args: {
               sort,
               filter,
               search,
               pageSize: correctPageSize,
               currentPage
           }
       };

       const infoOptions = {
           args: {
               filter,
               search
           }
       };

       requestProductList(options);

       if (!isWidget) {
           requestProductListInfo(infoOptions);
       }
   };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
