import { connect } from 'react-redux';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    RouterContainer as SourceRouter
} from 'SourceComponent/Router/Router.container';
import history from 'Util/History';

import Router from './Router.component';
import { LOCATION_PATH } from './Router.config';

/** @namespace AllHomePwafe/Component/Router/Container/sourceMapStateToProps */
export const sourceMapStateToProps = (state) => ({
    ...mapStateToProps(state),
    appConfig: state.ConfigReducer.timezone, // Check for this value to verify if the config are loaded
    storeLocatorPath: state.ConfigReducer.kslocator_general_url
});

/** @namespace AllHomePwafe/Component/Router/Container/RouterContainer */
export class RouterContainer extends SourceRouter {
    /**
     * @extends to update current path as a body class
     */

    componentDidMount() {
        super.componentDidMount();
        this.updateCurrentPathAsBodyClass(history.location.pathname);
        history.listen(({ pathname = '' }) => {
            this.updateCurrentPathAsBodyClass(pathname);
        });
    }

    updateCurrentPathAsBodyClass(pathname) {
        const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        const currentPathAsAClassName = pathname.split('').map((char, key) => {
            if (!key && char === '/') {
                return '';
            }
            if (char === '/' || char === '.' || char === ' ' || format.test(char)) {
                return '-';
            }

            return char;
        }).join('');

        const sameAsCurrentBodyClass = document.querySelector(`body.${LOCATION_PATH}${currentPathAsAClassName}`);

        if (!sameAsCurrentBodyClass) {
            const locationSelector = `body[class*="${LOCATION_PATH}"]`;
            const matchingClassList = document.querySelector(locationSelector)?.classList;
            matchingClassList?.forEach((className) => {
                if (className.startsWith(LOCATION_PATH)) {
                    document.body.classList.remove(className);
                }
            });
            if (currentPathAsAClassName) {
                document.body.classList.add(`${LOCATION_PATH}${currentPathAsAClassName}`);
            }
        }
    }

    /**
   * Extended to Re-trigger Router when url changes to or from checkout
   * @extends
   */

    render() {
        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        const { appConfig, storeLocatorPath } = this.props;

        if (!appConfig) {
            return null;
        }

        return (
          <Router
            key={ isCheckout }
            isCheckout={ isCheckout }
            { ...this.containerProps() }
            storeLocatorPath={ storeLocatorPath }
          />
        );
    }
}

export default connect(sourceMapStateToProps, mapDispatchToProps)(RouterContainer);
