/** @namespace Store/Config/Reducer */
import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';
import {
    ConfigReducer as sourceConfigReducer,
    filterStoreConfig, getCheckoutAgreementData, getCountryData, getCurrencyData,
    getIndexedRatings
} from 'SourceStore/Config/Config.reducer';

/** @namespace AllHomePwafe/Store/Config/Reducer/getLabelConfigData */
export const getLabelConfigData = (configData) => configData.configs[0];

/** @namespace AllHomePwafe/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (state, action) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            km_getLabels: labelConfig,
            cartDisplayConfig = {}
        } = {}
    } = action;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;
        window.secure_base_media_url = secure_base_media_url;

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currencyData: getCurrencyData(currencyData, state),
            labelConfig: getLabelConfigData(labelConfig),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };

    default:
        return sourceConfigReducer(state, action);
    }
};

export default ConfigReducer;
