export const SET_FLASH_SALE_WIDGET_DATA = 'SET_FLASH_SALE_WIDGET_DATA';
export const SET_FLASH_SALE_WIDGET_LOADING_STATE = 'SET_FLASH_SALE_WIDGET_LOADING_STATE';

/** @namespace AllHomePwafe/Store/FlashSale/Action/setFlashSaleWidgetData */
export const setFlashSaleWidgetData = (flashSaleWidgetData) => ({
    type: SET_FLASH_SALE_WIDGET_DATA,
    flashSaleWidgetData
});

/** @namespace AllHomePwafe/Store/FlashSale/Action/setFlashSaleWidgetLoadingState */
export const setFlashSaleWidgetLoadingState = (loadingState) => ({
    type: SET_FLASH_SALE_WIDGET_LOADING_STATE,
    loadingState
});
