import {
    debounce,
    formatURI, getGraphqlEndpoint, getStoreCodePath, getWindowId,
    handleConnectionError, HTTP_201_CREATED,
    HTTP_410_GONE, listenForBroadCast, putPersistedQuery
} from 'SourceUtil/Request/Request';
import { getAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { GRAPHQL_AUTHORIZATION_ERROR } from 'Util/Request/Request.config';
import getStore from 'Util/Store';

/** @namespace AllHomePwafe/Util/Request/isGenerateCustomerTokenQuery */
export const isGenerateCustomerTokenQuery = (query = '') => query.includes('generateCustomerToken');

export const LOCATION_DATA = 'locationData';
export const FORM_IDS = {
    DISTRICT: 'SelectionLocationDistrict'
};

/** @namespace AllHomePwafe/Util/Request/checkForErrors */
export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors, data } = res;
    if (errors) {
        const isAuthorizationError = (Array.isArray(errors) ? errors : [])
            .find((error) => error?.extensions?.category === GRAPHQL_AUTHORIZATION_ERROR);

        if (isAuthorizationError) {
            const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
            MyAccountDispatcher.then(
                ({ default: dispatcher }) => dispatcher.logout(true, getStore().dispatch)
            );
        }

        return reject(errors);
    }

    return resolve(data);
});

/** @namespace AllHomePwafe/Util/Request/parseResponse */
export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        /** @namespace AllHomePwafe/Util/Request/then */
        (res) => res.json().then(
            /** @namespace AllHomePwafe/Util/Request/json/then */
            (res) => resolve(checkForErrors(res)),
            /** @namespace AllHomePwafe/Util/Request/json/then */
            () => handleConnectionError('Can not transform JSON!') && reject()
        ),
        /** @namespace AllHomePwafe/Util/Request/then */
        (err) => handleConnectionError('Can not establish connection!') && reject(err)
    );
});

/**
 * @returns {city,district,region} selected data or false
 * @namespace AllHomePwafe/Util/Request/selectedLocation */
export const selectedLocation = () => {
    const locationData = BrowserDatabase.getItem(LOCATION_DATA) || {};
    const { DISTRICT } = FORM_IDS;
    const selectedData = {
        district: locationData[DISTRICT] || false
    };

    return JSON.stringify(selectedData);
};

/** @namespace AllHomePwafe/Util/Request/getAppSource */
export const getAppSource = () => {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isIOS = !!navigator.platform.match(/iPhone|iPod|iPad/);
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;
    // eslint-disable-next-line no-nested-ternary
    const isTWA = isAndroid ? document.referrer.includes('android-app://')
        : (isIOS ? !/safari/i.test(navigator.userAgent) : false);

    if (isAndroid && isTWA) {
        return 'android-app';
    }
    if (isAndroid && isPWA) {
        return 'android-pwa';
    }
    if (isAndroid) {
        return 'android-web';
    }
    if (isIOS && isTWA) {
        return 'ios-app';
    }
    if (isIOS && isPWA) {
        return 'ios-pwa';
    }
    if (isIOS) {
        return 'ios-web';
    }
    if (isPWA) {
        return 'desktop-pwa';
    }

    return 'desktop-web';
};

/** @namespace AllHomePwafe/Util/Request/appendTokenToHeaders */
export const appendTokenToHeaders = (headers) => {
    const token = getAuthorizationToken();

    return {
        ...headers,
        'Request-Source': getAppSource(),
        Authorization: token ? `Bearer ${token}` : ''
    };
};

/** @namespace AllHomePwafe/Util/Request/isInstallmentPriceHeader */
export const isInstallmentPriceHeader = (query) => {
    if (query.indexOf('s_placeOrder') === -1) {
        return null;
    }

    const state = getStore().getState();
    const { isInstallmentPrice } = state.PaymentReducer;

    return isInstallmentPrice;
};

/**
 * @extends to add 'Select-Location and Is-GenerateCustomerToken-Query' Header
 * @namespace AllHomePwafe/Util/Request/postFetch */
export const postFetch = (graphQlURI, query, variables) => {
    const headers = appendTokenToHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Selected-Location': selectedLocation(),
        'Is-GenerateCustomerToken-Query': isGenerateCustomerTokenQuery(query)
    });
    const installmentPriceHeaderValue = isInstallmentPriceHeader(query);
    if (installmentPriceHeaderValue !== null) {
        headers['Is-Installment-Price'] = installmentPriceHeaderValue;
    }

    return fetch(graphQlURI,
        {
            method: 'POST',
            body: JSON.stringify({ query, variables }),
            headers
        });
};

/** @namespace AllHomePwafe/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;
    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables));
};

/**
 * @extends to add 'Select-Location and Is-GenerateCustomerToken-Query' Header
 * @namespace AllHomePwafe/Util/Request/getFetch */

export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'application/json',
            'Application-Model': `${name}_${getWindowId()}`,
            Accept: 'application/json',
            'Selected-Location': selectedLocation(),
            'Is-GenerateCustomerToken-Query': isGenerateCustomerTokenQuery()
        })
    });

/** @namespace AllHomePwafe/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace AllHomePwafe/Util/Request/getFetch/then */
            (res) => {
                if (res.status === HTTP_410_GONE) {
                    putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                        /** @namespace AllHomePwafe/Util/Request/putPersistedQuery/then */
                        (putResponse) => {
                            if (putResponse.status === HTTP_201_CREATED) {
                                getFetch(uri, name).then(
                                    /** @namespace AllHomePwafe/Util/Request/getFetch/then */
                                    (res) => resolve(res)
                                );
                            }
                        }
                    );
                } else {
                    resolve(res);
                }
            }
        );
    }));
};

export {
    debounce,
    formatURI, getGraphqlEndpoint, getStoreCodePath, getWindowId,
    handleConnectionError, listenForBroadCast, putPersistedQuery
};
