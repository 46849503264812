import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList';

import './ARLink.style.scss';

/** @namespace AllHomePwafe/Component/ARLink/Component/ARLinkComponent */
export class ARLinkComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        onARLinkClick: PropTypes.func.isRequired,
        isLinkTextHidden: PropTypes.bool
    };

    static defaultProps = {
        isLinkTextHidden: false
    };

    render() {
        const {
            isLinkTextHidden,
            onARLinkClick,
            product: { attributes: { ar_link } = { ar_link: null } } = {}
        } = this.props;

        if (!ar_link || !ar_link.attribute_value) {
            return null;
        }

        const { attribute_value } = ar_link;

        return (
            <a block="ARLink" elem="Container" href={ attribute_value } onClick={ onARLinkClick }>
                <div block="ARLink" elem="ImageContainer" />
                { !isLinkTextHidden && (
                    <div block="ARLink" elem="LinkText">
                        { __('View in Augmented Reality') }
                    </div>
                ) }
            </a>
        );
    }
}

export default ARLinkComponent;
