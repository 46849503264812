import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ShopByLookSlide } from 'Type/ShopByLook';

import ShopByLookHomeBanner from './ShopByLookHomeBanner.component';

export const ShopByLookDispatcher = import('Store/ShopByLook/ShopByLook.dispatcher');

/** @namespace AllHomePwafe/Component/ShopByLookHomeBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    slide: state.ShopByLookReducer.slides[0],
    homePageSlideId: state.ConfigReducer.lookbookslider_general_home_page_shop_by_look_id,
    isHomePageBannerVisible: state.ConfigReducer.lookbookslider_general_enable_home_page_section === '1'
});

/** @namespace AllHomePwafe/Component/ShopByLookHomeBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getSlideById: (slide_id) => ShopByLookDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSlideById({
            slide_id,
            dispatch
        })
    )
});

/** @namespace AllHomePwafe/Component/ShopByLookHomeBanner/Container/ShopByLookHomeBannerContainer */
export class ShopByLookHomeBannerContainer extends PureComponent {
    static propTypes = {
        slide: ShopByLookSlide,
        isHomePageBannerVisible: PropTypes.bool.isRequired,
        homePageSlideId: PropTypes.number.isRequired,
        getSlideById: PropTypes.func.isRequired
    };

    static defaultProps = {
        slide: {}
    };

    componentDidMount() {
        const { getSlideById, homePageSlideId } = this.props;
        getSlideById(homePageSlideId);
    }

    render() {
        const { isHomePageBannerVisible } = this.props;
        if (!isHomePageBannerVisible) {
            return null;
        }

        return (
            <ShopByLookHomeBanner
              /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopByLookHomeBannerContainer);
