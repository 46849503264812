import Notification from 'Component/Notification';
import {
    NotificationList as SourceNotificationListComponent
} from 'SourceComponent/NotificationList/NotificationList.component';

/** @namespace AllHomePwafe/Component/NotificationList/Component/NotificationListComponent */
export class NotificationListComponent extends SourceNotificationListComponent {
    /**
     * @extends to add currentStep mod
     */

    render() {
        const { onHideNotification, notifications, currentStep } = this.props;

        return (
            <div block="NotificationList" mods={ { [currentStep]: !!currentStep } }>
                { Object.keys(notifications).map((id) => (
                    <Notification
                      key={ id }
                      notificationId={ id }
                      notification={ notifications[id] }
                      onHideNotification={ onHideNotification }
                    />
                )) }
            </div>
        );
    }
}

export default NotificationListComponent;
