import { UPDATE_JSONLD } from './JsonLd.action';

/** @namespace AllHomePwafe/Store/JsonLd/Reducer/getInitialState */
export const getInitialState = () => ({
    items: []
});

/** @namespace AllHomePwafe/Store/JsonLd/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_JSONLD:
        return {
            ...state,
            items: payload
        };

    default:
        return state;
    }
};

export default MetaReducer;
