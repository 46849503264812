export * from 'SourceComponent/Header/Header.config';

export const ORDER_TRACK = 'order_track';
export const STICKY_HEADER_DELAY = 100;
export const STICK_HEADER_MIN_HEIGHT = 50;
export const CHECKOUT_PROCESSING = 'checkout_processing';
export const CHECKOUT_INVALID = 'checkout_invalid';
export const CHECKOUT_FAIL = 'checkout_invalid';
export const CREATE_PASSWORD = 'create_password';
export const BLOG_LIST = 'blog_list';
export const BLOG = 'blog';
export const SHOP_BY_LOOK_LIST = 'shop_by_look_list';
export const SHOP_BY_LOOK = 'shop_by_look';
export const STORE_PREFIX = 'AH';
export const SITEMAP = 'sitemap';
export const POPUP_NO_HEADER = 'popup_no_header';
