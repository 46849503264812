import { InstallPromptIOS as SourceIos } from 'SourceComponent/InstallPromptIOS/InstallPromptIOS.component';

/** @namespace AllHomePwafe/Component/InstallPromptIOS/Component/InstallPromptIOSComponent */
export class InstallPromptIOSComponent extends SourceIos {
    renderCloseButton() {
        const { handleBannerClose } = this.props;

        return (
            <button
              block="InstallPromptAndroid"
              elem="Close"
              onClick={ handleBannerClose }
              aria-label={ __('Close') }
            />
        );
    }

    renderContent() {
        return (
            <span>
                <p block="InstallPromptIOS" elem="Heading">
                    <span
                      block="InstallPrompt"
                      elem="title"
                    >
                        { __('Browse website in full-screen in your phone') }
                    </span>
                </p>
                <p block="InstallPromptIOS" elem="Content">
                    <span>{ __('Tap') }</span>
                    <span block="InstallPromptIOS" elem="Share" />
                    <span>{ __(', then') }</span>
                    <span block="InstallPromptIOS" elem="Plus" />
                    <span>{ __(' for adding to home screen') }</span>
                </p>

            </span>
        );
    }

    render() {
        return (
            <div block="InstallPromptIOS">
                { this.renderContent() }
                { this.renderCloseButton() }
            </div>
        );
    }
}

export default InstallPromptIOSComponent;
