/* eslint-disable max-lines */
import InstallPrompt from 'Component/InstallPrompt';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import Menu from 'Component/Menu';
import OfflineNotice from 'Component/OfflineNotice';
import SearchField from 'Component/SearchField';
import Topbar from 'Component/TopBar';
import { CATEGORY_STEP } from 'Route/CategoryPage/CategoryPage.config';
import {
    BILLING_STEP,
    DETAILS_STEP,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { Header as SourceHeader } from 'SourceComponent/Header/Header.component';
import CSS from 'Util/CSS';

import {
    BLOG, BLOG_LIST,
    CART_OVERLAY,
    CHECKOUT_SUCCESS,
    CREATE_PASSWORD,
    CUSTOMER_WISHLIST,
    FILTER, ORDER_TRACK, POPUP_NO_HEADER,
    SEARCH,
    SHOP_BY_LOOK, SHOP_BY_LOOK_LIST,
    SITEMAP
} from './Header.config';

/** @namespace AllHomePwafe/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        wishlist: this.renderWishlist.bind(this),
        account: this.renderAccount.bind(this),
        minicart: this.renderMinicart.bind(this),
        search: this.renderSearchField.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this),
        checkoutStep: this.renderCheckoutStep.bind(this)
    };

    stateMap = {
        ...this.stateMap,
        [FILTER]: {
            back: true,
            clear: true,
            title: true
        },
        [CREATE_PASSWORD]: {
            title: true,
            back: true
        },
        [BLOG_LIST]: {
            title: true,
            back: true
        },
        [BLOG]: {
            title: true,
            back: true
        },
        [SITEMAP]: {
            title: true,
            back: true
        },
        [ORDER_TRACK]: {
            title: true,
            back: true
        },
        [SHOP_BY_LOOK_LIST]: {
            title: true,
            back: true
        },
        [SHOP_BY_LOOK]: {
            title: true,
            back: true
        },
        [POPUP_NO_HEADER]: {
            title: true,
            back: true
        },
        [CHECKOUT_SUCCESS]: {
            title: true,
            account: false,
            search: true
        }
    };

    renderCheckoutStep() {
        const { isCheckout, currentStep } = this.props;
        if (isCheckout && currentStep !== DETAILS_STEP) {
            const isShippingStepActive = currentStep === SHIPPING_STEP;

            const shippingHeaderContent = isShippingStepActive
                ? <span>{ __('Shipping') }</span> : <Link to="/checkout/shipping">{ __(' Shipping') }</Link>;

            return (
                <ul block="Header" elem="CheckoutStep" key="CheckoutStep">
                    <li
                      block="Header"
                      elem="ShippingStep"
                      mods={ { isActive: isShippingStepActive } }
                    >
                        { shippingHeaderContent }
                    </li>

                    <li
                      block="Header"
                      elem="BillingStep"
                      mods={ { isActive: currentStep === BILLING_STEP } }
                    >
                        <span>{ __('Payment') }</span>
                    </li>
                </ul>
            );
        }

        return false;
    }

    renderTopMenu() {
        return <Topbar />;
    }

    renderOkButton(isVisible = false) {
        const { onOkButtonClick } = this.props;

        return (
            <button
              key="ok"
              block="Header"
              elem="Button"
              mods={ { type: 'ok', isVisible } }
              onClick={ onOkButtonClick }
              aria-label="Save changes"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
            >
                { __('SAVE') }
            </button>
        );
    }

    renderWishlist() {
        const { isSignedIn } = this.props;

        if (!isSignedIn) {
            return null;
        }

        return (
            <Link to="/my-account/my-wishlist" block="Header" elem="WishList" key="Wishlist">
                <span>{ __('Wishlist') }</span>
            </Link>
        );
    }

    renderAccountOverlayFallback() {
        return null;
    }

    renderLogo(isVisible = false) {
        return (
            <Link
              to="/"
              aria-label="Go to homepage by clicking on ScandiPWA logo"
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { this.renderLogoImage() }
            </Link>
        );
    }

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logo_height}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logo_width}px`);

        return (
            <Logo
              src={ header_logo_src }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderMinicartItemsQty() {
        const { cartTotals: { items_qty } } = this.props;
        if (!items_qty) {
            return (
            <span
              aria-label="Items in cart"
              block="Header"
              elem="MinicartItemCount"
            >
               0
            </span>
            );
        }

        return (
            <span
              aria-label="Items in cart"
              block="Header"
              elem="MinicartItemCount"
            >
                { items_qty }
            </span>
        );
    }

    /**
     * @override to set category header as an H2 tag when sub header exists
     */

    renderTitle(isVisible = false) {
        const { navigationState: { title }, currentStep, category: { sub_header } = {} } = this.props;
        const Tag = currentStep === CATEGORY_STEP && sub_header ? 'h2' : 'h1';

        return (
            <Tag
              key="title"
              block="Header"
              elem="Title"
              mods={ { isVisible } }
            >
                { title }
            </Tag>
        );
    }

    renderMenu() {
        const { isCheckout, currentStep } = this.props;

        if (isCheckout && currentStep !== DETAILS_STEP) {
            return null;
        }

        return <Menu />;
    }

    renderHeaderOutsideClickButton() {
        const {
            menuStatus, handleHeaderOutsideClick
        } = this.props;

        if (!menuStatus) {
            return false;
        }

        return (
            <button block="Header" elem="HeaderOutsideClick" onClick={ handleHeaderOutsideClick }>
                <span>Render outside click button</span>
            </button>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay,
            currentStep
        } = this.props;

        if (isCheckout && currentStep !== DETAILS_STEP) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            device,
            isHeaderSticky,
            isSignedIn,
            currentStep,
            menuStatus
        } = this.props;

        if (!device.isMobile) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section
              block="Header"
              elem="Wrapper"
              mods={ {
                  isCheckout,
                  [currentStep]: !!currentStep
              } }
            >
                <header
                  block="Header"
                  mods={ {
                      isMenuActive: !!menuStatus,
                      name,
                      isHiddenOnMobile,
                      isCheckout,
                      isHeaderSticky,
                      hasWishlist: isSignedIn
                  } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    { this.renderHeaderOutsideClickButton() }
                    <InstallPrompt />
                    { this.renderTopMenu() }
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                    </nav>
                    { this.renderMenu() }
                </header>
                <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
