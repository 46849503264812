import {
    getInitialState,
    ProductListReducer as sourceProductReducer, reduceFilters
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import { UPDATE_PRODUCT_LIST_INFO } from 'Store/ProductListInfo/ProductListInfo.action';

/** @namespace AllHomePwafe/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        const {
            selectedFilter,
            products: {
                filters: availableFilters = [],
                min_price,
                max_price,
                sort_fields: sortFields
            } = {}
        } = action;

        // Sort the filters based on the position attribute
        availableFilters
            .sort((record1, record2) => (record1.position || 0) - (record2.position || 0));

        return {
            ...state,
            filters: reduceFilters(availableFilters),
            sortFields,
            minPrice: Math.floor(min_price),
            maxPrice: Math.ceil(max_price),
            isLoading: false,
            selectedFilter
        };
    }

    default:
        return sourceProductReducer(state, action);
    }
};

export default ProductListReducer;
