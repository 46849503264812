import { getAuthorizationToken, refreshAuthorizationToken } from 'SourceUtil/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import getStore from 'Util/Store';

export * from 'SourceUtil/Auth/Token';

export const AUTH_TOKEN = 'auth_token';

export const TWENTY_FOUR_HOUR = 86400;

/** @namespace AllHomePwafe/Util/Auth/Token/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    const state = getStore().getState();
    const {
        cookie_lifetime = TWENTY_FOUR_HOUR
    } = state.ConfigReducer;

    BrowserDatabase.setItem(token, AUTH_TOKEN, cookie_lifetime);
};

/** @namespace AllHomePwafe/Util/Auth/Token/isSignedIn */
export const isSignedIn = () => {
    const _isSignedIn = !!getAuthorizationToken();
    const state = getStore().getState();

    if (_isSignedIn && !state.MyAccountReducer.isSignedIn) {
        window.location.reload();
        return true;
    }

    if (!_isSignedIn && state.MyAccountReducer.isSignedIn) {
        const MyAccountDispatcher = import('../../store/MyAccount/MyAccount.dispatcher');
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(true, getStore().dispatch)
        );
    } else if (_isSignedIn && state.MyAccountReducer.isSignedIn) {
        refreshAuthorizationToken();
    }

    return _isSignedIn;
};
