export * from 'SourceComponent/ProductListPage/ProductListPage.config';
export const PRODUCT_LIST_WIDGET = 'ProductListWidget';
export const CAROUSEL_BREAKPOINTS = {
    desktop: {
        breakpoint: { max: 9999, min: 1024 },
        items: 6,
        partialVisibilityGutter: 0
    },
    tablet: {
        breakpoint: { max: 1023, min: 769 },
        items: 3,
        partialVisibilityGutter: 16
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 2,
        partialVisibilityGutter: 16
    }
};
