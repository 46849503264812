import { PureComponent } from 'react';

import Loader from 'Component/Loader';
import ShopByLookImage from 'Component/ShopByLookImage';
import { ShopByLookSlide } from 'Type/ShopByLook';

import './ShopByLookHomeBanner.style';

/** @namespace AllHomePwafe/Component/ShopByLookHomeBanner/Component/ShopByLookHomeBannerComponent */
export class ShopByLookHomeBannerComponent extends PureComponent {
    static propTypes = {
        slide: ShopByLookSlide
    };

    static defaultProps = {
        slide: {}
    };

    renderBannerImage() {
        const { slide: { slide_id } } = this.props;
        if (!slide_id) {
            return null;
        }

        return (
            <div block="ShopByLookHomeBanner" elem="ImageContainer">
                <ShopByLookImage slideId={ slide_id } />
            </div>
        );
    }

    render() {
        const { slide: { slide_id } } = this.props;
        return (
            <div block="ShopByLookHomeBanner" elem="Container">
                <Loader isLoading={ !slide_id } />
                { this.renderBannerImage() }
            </div>
        );
    }
}

export default ShopByLookHomeBannerComponent;
