import {
    SBL_CLEAR_SLIDE, SBL_UPDATE_HOTSPOTS,
    SBL_UPDATE_SLIDES, SBL_UPDATE_VISIBLE_HOTSPOT_ID
} from 'Store/ShopByLook/ShopByLook.action';

/** @namespace AllHomePwafe/Store/ShopByLook/Reducer/updateSlidesData */
export const updateSlidesData = (action, state) => {
    const { slides = [] } = action;

    return { ...state, slides };
};

/** @namespace AllHomePwafe/Store/ShopByLook/Reducer/updateSlideHotspots */
export const updateSlideHotspots = (action, state) => {
    const { slide_id, hotspots } = action;
    const newSlides = Array.from(state.slides);
    const foundSlideId = newSlides.find((slideRecord) => slideRecord.slide_id === slide_id);
    if (!foundSlideId) {
        return state;
    }
    foundSlideId.hotspots = hotspots;

    return { ...state, slides: newSlides };
};

/** @namespace AllHomePwafe/Store/ShopByLook/Reducer/getInitialState */
export const getInitialState = () => ({
    slides: [],
    isRelatedSlidesLoaded: false,
    visibleHotspotId: '0'
});

/** @namespace AllHomePwafe/Store/ShopByLook/Reducer/ShopByLookReducer */
export const ShopByLookReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case SBL_CLEAR_SLIDE:
        return { ...state, slide: {}, isRelatedSlidesLoaded: false };
    case SBL_UPDATE_SLIDES:
        return updateSlidesData(action, state);
    case SBL_UPDATE_HOTSPOTS:
        return updateSlideHotspots(action, state);
    case SBL_UPDATE_VISIBLE_HOTSPOT_ID:
        const { visibleHotspotId } = action;
        return { ...state, visibleHotspotId };
    default:
        return state;
    }
};

export default ShopByLookReducer;
