import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { MIN_DIMENSION, MOBILE_MIN_DIMENSION } from 'Component/ShopByLookProductPopup/ShopByLookProductPopup.config';
import { updateVisibleHotspotId } from 'Store/ShopByLook/ShopByLook.action';
import { ShopByLookSlide, SlideHotSpot } from 'Type/ShopByLook';

import { ShopByLookProductPopupComponent } from './ShopByLookProductPopup.component';

/** @namespace AllHomePwafe/Component/ShopByLookProductPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    visibleHotspotId: state.ShopByLookReducer.visibleHotspotId,
    iconPathFromConfig: state.ConfigReducer.lookbookslider_general_hotspot_icon
});

/** @namespace AllHomePwafe/Component/ShopByLookProductPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateVisibleHotspotId: (visibleHotspotId) => dispatch(updateVisibleHotspotId(visibleHotspotId))
});

/** @namespace AllHomePwafe/Component/ShopByLookProductPopup/Container/ShopByLookProductPopupContainer */
export class ShopByLookProductPopupContainer extends PureComponent {
    static propTypes = {
        scaleX: PropTypes.number.isRequired,
        scaleY: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
        slide: ShopByLookSlide,
        visibleHotspotId: PropTypes.string.isRequired,
        updateVisibleHotspotId: PropTypes.func.isRequired,
        iconPathFromConfig: PropTypes.string.isRequired,
        hotspot: SlideHotSpot
    };

    static defaultProps = {
        hotspot: {},
        slide: {}
    };

    state = {
        isPopupVisible: false, top: 0, left: 0, height: 0, width: 0
    };

    containerFunctions = {
        onClickPopup: this.onClickPopup.bind(this),
        onHoverPopup: this.onHoverPopup.bind(this),
        closePopup: this.closePopup.bind(this)
    };

    componentDidUpdate(prevProps) {
        this.changePopupPosition(prevProps);
        const { hotspot: { id: currentHotSpotId }, visibleHotspotId } = this.props;
        if (currentHotSpotId !== visibleHotspotId) {
            this.closePopup();
        }
    }

    containerProps() {
        const {
            slide: {
                slider: {
                    items: [{ hotspot_image_path = '' } = {}] = [{}]
                } = {}
            },
            iconPathFromConfig
        } = this.props;

        if (hotspot_image_path) {
            return { iconImagePath: hotspot_image_path };
        }

        if (iconPathFromConfig) {
            return { iconImagePath: iconPathFromConfig };
        }

        return '';
    }

    changePopupPosition(prevProps) {
        const { scaleX, scaleY, hotspot } = this.props;
        const { scaleX: prevScaleX, scaleY: prevScaleY } = prevProps;
        if (scaleX !== prevScaleX || scaleY !== prevScaleY) {
            const originalHeight = hotspot.height * scaleY;
            const originalWidth = hotspot.width * scaleX;
            const originalTop = hotspot.top * scaleX;
            const originalLeft = hotspot.left * scaleY;
            const height = Math.max(this.getMinContainerDimension(), hotspot.height * scaleY);
            const width = Math.max(this.getMinContainerDimension(), hotspot.width * scaleX);
            const heightDiff = height - originalHeight;
            const widthDiff = width - originalWidth;

            const top = originalTop - heightDiff > 0 ? originalTop - heightDiff : originalTop;
            const left = originalLeft - widthDiff > 0 ? originalLeft - widthDiff : originalLeft;
            this.updatePopupPosition({
                top, left, height, width
            });
        }
    }

    getMinContainerDimension() {
        const { isMobile } = this.props;
        return isMobile ? MOBILE_MIN_DIMENSION : MIN_DIMENSION;
    }

    onHoverPopup() {
        const { hotspot: { id: hotSpotId }, updateVisibleHotspotId, isMobile } = this.props;
        if (!isMobile) {
            updateVisibleHotspotId(hotSpotId);
            this.setState({ isPopupVisible: true });
        }
    }

    onClickPopup() {
        const { hotspot: { id: hotSpotId }, updateVisibleHotspotId, isMobile } = this.props;
        const { isPopupVisible } = this.state;
        if (isMobile) {
            updateVisibleHotspotId(isPopupVisible ? null : hotSpotId);
            this.setState({ isPopupVisible: !isPopupVisible });
        }
    }

    verifyElementRecursively({ el, className }) {
        if (el.className === className) {
            return true;
        }
        const elementStore = { selectedElement: el };
        while (elementStore.selectedElement.parentNode) {
            elementStore.selectedElement = elementStore.selectedElement.parentNode;
            if (elementStore.selectedElement.className === className) {
                return true;
            }
        }

        return null;
    }

    closePopup(event) {
        const { isMobile } = this.props;
        if (!isMobile) {
            if (event) {
                const isContainerHovered = this.verifyElementRecursively({
                    el: event.relatedTarget,
                    className: 'ShopByLookProductPopup'
                });
                const isPopupHovered = this.verifyElementRecursively({
                    el: event.relatedTarget,
                    className: 'react-tiny-popover-container'
                });

                if (!isContainerHovered && !isPopupHovered) {
                    this.setState({ isPopupVisible: false });
                }
            } else {
                this.setState({ isPopupVisible: false });
            }
        }
    }

    updatePopupPosition({
        top, left, height, width
    }) {
        this.setState({
            top, left, height, width
        });
    }

    render() {
        return (
            <ShopByLookProductPopupComponent
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.props }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.state }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerFunctions }
                /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopByLookProductPopupContainer);
