import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import { PAY_IN_STORE_DISCLAIMER_POPUP_ID } from './PayInStoreDisclaimerPopup.config';

import './PayInStoreDisclaimerPopup.style';

/** @namespace AllHomePwafe/Component/PayInStoreDisclaimerPopup/Component/PayInStoreDisclaimerPopupComponent */
export class PayInStoreDisclaimerPopupComponent extends PureComponent {
    static propTypes = {
        disclaimerMessage: PropTypes.string.isRequired,
        disclaimerMessageTitle: PropTypes.string.isRequired,
        handleGoBack: PropTypes.func.isRequired
    };

    renderContent() {
        const { disclaimerMessage } = this.props;

        return (
            <div block="PayInStoreDisclaimerPopup" elem="Wrapper">
                <div block="PayInStoreDisclaimerPopup" elem="Message">
                    { /* eslint-disable-next-line max-len */ }
                        { disclaimerMessage }
                </div>
            </div>
        );
    }

    renderHeader() {
        const { disclaimerMessageTitle } = this.props;

        return (
            <h3 block="PayInStoreDisclaimerPopup" elem="Title">
                { disclaimerMessageTitle }
            </h3>
        );
    }

    renderButton() {
        const { handleGoBack } = this.props;

        return (
            <button
              block="PayInStoreDisclaimerPopup"
              elem="Ok"
              mix={ { block: 'Button' } }
              onClick={ handleGoBack }
            >
                { __('Close') }
            </button>
        );
    }

    render() {
        const { handleGoBack } = this.props;

        return (
            <Popup
              mix={ { block: 'PayInStoreDisclaimerPopup' } }
              id={ PAY_IN_STORE_DISCLAIMER_POPUP_ID }
              isNoHeaderPopup
              onClose={ handleGoBack }
            >
                { this.renderHeader() }
                { this.renderContent() }
                { this.renderButton() }
            </Popup>
        );
    }
}

export default PayInStoreDisclaimerPopupComponent;
