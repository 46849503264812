import ClickOutside from 'SourceComponent/ClickOutside';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';

/** @namespace AllHomePwafe/Component/Popup/Component/PopupComponent */
export class PopupComponent extends SourcePopup {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    handleClickOutsideFn = () => {
        const { clickOutside, device: { isMobile } } = this.props;
        if (!clickOutside || isMobile) {
            return;
        }
        this.hidePopUp();
    };

    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={ this.handleClickOutsideFn }>
                <div block="Popup" elem="Container">
                    { this.renderCloseButton() }
                    <div block="Popup" elem="Content" mix={ contentMix }>
                        <header block="Popup" elem="Header">
                            { this.renderTitle() }
                        </header>
                        { children }
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default PopupComponent;
