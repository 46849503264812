import SourceOverlayComponent from 'SourceComponent/Overlay/Overlay.component';

import './Overlay.style';

/** @namespace AllHomePwafe/Component/Overlay/Component/OverlayComponent */
export class OverlayComponent extends SourceOverlayComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { activeOverlay } = this.props;
        const { activeOverlay: prevActiveOverlay } = prevProps;
        const prevWasVisible = this.getIsVisible(prevProps);

        const isVisible = this.getIsVisible();
        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }
        // If activeoverlay is removed then also call the hide, it is not called in case of the login popup from the checkout page.
        if ((!isVisible && prevWasVisible) || (!activeOverlay && prevActiveOverlay)) {
            this.onHide();
        }
    }
}

export default OverlayComponent;
