import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import history from 'Util/History';
import { debounce } from 'Util/Request';

import {
    STICK_HEADER_MIN_HEIGHT,
    STICKY_HEADER_DELAY
} from './Header.config';

export const MenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Menu/Menu.dispatcher'
);

/** @namespace AllHomePwafe/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSignedIn: state.MyAccountReducer.isSignedIn,
    currentStep: state.CurrentStepReducer.step,
    menuStatus: state.MenuReducer.status,
    category: state.CategoryReducer.category,
    savedNavStatus: state.MenuReducer.savedNavStatus
});

/** @namespace AllHomePwafe/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMenuStatus: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setMenuStatus(dispatch, options)
    ),
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state))
});

/** @namespace AllHomePwafe/Component/Header/Container/HeaderContainer */
export class HeaderContainer extends SourceHeaderContainer {
    /**
     * Extended to initial @function handleOnScroll
     * @extends
     */

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            isHeaderSticky: false
        };
        this.handleOnScroll = this.handleOnScroll.bind(this);
        this.handleOnScroll();
        window.addEventListener('scroll', debounce(this.handleOnScroll, STICKY_HEADER_DELAY));
    }

    handleOnScroll() {
        this.setState({
            isHeaderSticky: window.pageYOffset >= STICK_HEADER_MIN_HEIGHT
        });
    }

    onClearButtonClick(e) {
        const { navigationState: { onCloseClick } } = this.props;

        if (onCloseClick) {
            onCloseClick(e);
        }

        super.onClearButtonClick(e);
    }

    containerFunctions = {
        ...this.containerFunctions,
        handleHeaderOutsideClick: this.handleHeaderOutsideClick.bind(this)
    };

    handleHeaderOutsideClick() {
        const { changeHeaderState, savedNavStatus, setMenuStatus } = this.props;
        changeHeaderState({ ...savedNavStatus });
        setMenuStatus(false);
    }

    /**
     * Overridden to add extra props and state
     * @override
     */

     containerProps = () => {
         const {
             activeOverlay,
             navigationState,
             cartTotals,
             header_logo_src,
             logo_alt,
             logo_height,
             logo_width,
             isLoading,
             isSignedIn,
             device,
             isWishlistLoading,
             currentStep,
             category,
             menuStatus
         } = this.props;

         const {
             isClearEnabled,
             searchCriteria,
             showMyAccountLogin,
             isHeaderSticky
         } = this.state;

         const {
             location: {
                 pathname
             }
         } = history;

         const isCheckout = pathname.includes(CHECKOUT_URL);

         return {
             activeOverlay,
             navigationState,
             cartTotals,
             header_logo_src,
             logo_alt,
             logo_height,
             logo_width,
             isLoading,
             isSignedIn,
             isClearEnabled,
             searchCriteria,
             isCheckout,
             showMyAccountLogin,
             device,
             isWishlistLoading,
             isHeaderSticky,
             currentStep,
             category,
             menuStatus
         };
     };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
