import { PureComponent } from 'react';

/** @namespace AllHomePwafe/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends PureComponent {
    /**
     * Temporally New version popup removed due to a bug
     * BUG : New version popup shows up for first time users
     * https://github.com/scandipwa/scandipwa/issues/2833
     */

    render() {
        return null;
    }
}

export default NewVersionPopupContainer;
