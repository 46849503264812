import BrowserDatabase from 'Util/BrowserDatabase';

import { SET_HEADER_LOCATION } from './LocationChanger.action';

export const LOCATION_DATA = 'locationData';
/** @namespace AllHomePwafe/Store/LocationChanger/Reducer/getInitialState */
export const getInitialState = () => ({
    location: BrowserDatabase.getItem(LOCATION_DATA) || {}
});

/** @namespace AllHomePwafe/Store/LocationChanger/Reducer/LocationChangerReducer */
export const LocationChangerReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_HEADER_LOCATION:
        const { location } = action;
        return {
            ...state,
            location
        };

    default:
        return state;
    }
};

export default LocationChangerReducer;
