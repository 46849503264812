/* eslint-disable max-lines */
import AddToCart from 'Component/AddToCart';
import ARLink from 'Component/ARLink';
import FlashSaleTimerComponent from 'Component/FlashSaleTimer';
import { FLASH_TIMER_MODES } from 'Component/FlashSaleTimer/FlashSaleTimer.config';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import ProductLabel from 'Component/ProductLabel';
import ProductPrice from 'Component/ProductPrice';
import ProductSaleLabel from 'Component/ProductSaleLabel';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import ProductCompareButton from 'SourceComponent/ProductCompareButton';
import { formatPrice } from 'Util/Price';
import { BUNDLE } from 'Util/Product/Types';

import { IN_STOCK } from './ProductCard.config';
/** @namespace AllHomePwafe/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends SourceProductCard {
    renderAddToCartButton() {
        const { product } = this.props;
        return (
            <AddToCart
              product={ product }
            />
        );
    }

    renderProductCompareButton() {
        const {
            product: { id },
            hideCompareButton
        } = this.props;

        if (hideCompareButton) {
            return null;
        }

        return (
            <ProductCompareButton
              productId={ id }
              product={ this.props.product }
              mix={ {
                  block: 'ProductCompareButton',
                  mods: { isGrey: true }
              } }
            />
        );
    }

    renderProductSaleLabel() {
        const { product } = this.props;
        return <ProductSaleLabel product={ product } />;
    }

    renderProductPrice() {
        const {
            product: { price_range, type_id },
            product,
            productCardProps,
            isBundleProductOutOfStock
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderConfigurablePriceBadge() }
                <ProductPrice
                  product={ product }
                  price={ price_range }
                  isPLP={ !!productCardProps }
                  mix={ { block: 'ProductCard', elem: 'Price' } }
                />
            </div>
        );
    }

    renderARLink() {
        const { product } = this.props;
        return <ARLink isLinkTextHidden product={ product } />;
    }

    renderMainDetails() {
        const { product: { name, product_seo_name } } = this.props;

        return this.renderCardLinkWrapper(
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ product_seo_name || name } length="medium" />
            </p>
        );
    }

    renderFlashSale() {
        const { product: { id: productId, flash_sale } } = this.props;

        return (
            <FlashSaleTimerComponent
              mods={ FLASH_TIMER_MODES.PLP }
              flashSale={ flash_sale }
              productId={ productId }
            />
        );
    }

    renderPicture(mix = {}) {
        const { product: { id, name }, thumbnail } = this.props;

        return (
            <Image
              imageRef={ this.imageRef }
              src={ thumbnail }
              alt={ name }
              ratio="custom"
              mix={ { block: 'ProductCard', elem: 'Picture', mix } }
              isPlaceholder={ !id }
            />
        );
    }

    renderSoldOutLable() {
        const {
            isLoading, product: { stock_status }, product
        } = this.props;

        return (
            <ProductLabel
              isLoading={ isLoading }
              isInStock={ stock_status === IN_STOCK }
              product={ product }
            />
        );
    }

    renderInstallmentLabel() {
        const {
            product: {
                installment_label,
                price_range: {
                    minimum_price: {
                        final_price: { currency } = {}
                    } = {}
                } = {}
            }
        } = this.props;

        if (!installment_label) {
            return null;
        }

        return (
            <span block="ProductCard" elem="InstallmentLabel">
                <span>{ __('Installment ') }</span>
                <span>{ __(' %s /mo', formatPrice(installment_label, currency)) }</span>
            </span>
        );
    }

    renderExclusivePriceText() {
        const {
            productOrVariant: {
                price_range: {
                    minimum_price: {
                        exclusive_price_value: {
                            currency,
                            value: exclusivePrice
                        } = {}
                    } = {}
                } = {}
            } = {}, exclusivePricePlpLabel
        } = this.props;

        if (!exclusivePrice) {
            return null;
        }

        return __(exclusivePricePlpLabel, formatPrice(exclusivePrice, currency));
    }

    renderConfigurablePriceBadge() {
        const { productOrVariant: { price_tiers = [] } = {} } = this.props;

        if ((price_tiers.length)) {
            return null;
        }

        return super.renderConfigurablePriceBadge();
    }

    renderCardContent() {
        const {
            renderContent
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                        { this.renderSoldOutLable() }
                        { this.renderProductSaleLabel() }
                        { this.renderReviews() }
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderAdditionalProductDetails() }
                        { this.renderMainDetails() }
                        <div block="ProductCard" elem="Exclusiveprice">
                            { this.renderExclusivePriceText() }
                        </div>
                        { this.renderProductPrice() }
                        { this.renderInstallmentLabel() }
                        { this.renderARLink() }
                        { this.renderFlashSale() }
                        { this.renderVisualConfigurableOptions() }
                    </div>
                </>
            ))
        );
    }

    renderContent() {
        const { children, isLoading } = this.props;
        return (
            <>
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { this.renderProductActions() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </>
        );
    }

    render() {
        const {
            isCarousel,
            mix,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        if (layout === 'list') {
            return super.render();
        }

        if (isCarousel) {
            return (
                <>
                    { this.renderContent() }
                </>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
            >
                { this.renderContent() }
            </li>
        );
    }
}

export default ProductCardComponent;
