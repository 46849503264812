import { getInitialState, TOP_NAVIGATION_TYPE } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.reducer';

import { NavigationReducer as sourceNavigationReducer } from 'SourceStore/Navigation/Navigation.reducer';
import { CHANGE_NAVIGATION_HEADER_TITLE } from 'Store/Navigation/Navigation.action';

export * from 'SourceStore/Navigation/Navigation.reducer';

/** @namespace AllHomePwafe/Store/Navigation/Reducer/NavigationReducer */
export const NavigationReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case CHANGE_NAVIGATION_HEADER_TITLE:
        const topNavigationType = state[TOP_NAVIGATION_TYPE];
        return {
            ...state,
            [TOP_NAVIGATION_TYPE]: {
                navigationStateHistory: topNavigationType.navigationStateHistory,
                navigationState: { ...topNavigationType.navigationState, title: action.title }
            }
        };
    default:
        return sourceNavigationReducer(state, action);
    }
};

export default NavigationReducer;
