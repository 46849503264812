import { ProductListInfoDispatcher as SourceProductListInfoDispatcher }
    from 'SourceStore/ProductListInfo/ProductListInfo.dispatcher';

/**
 * Search Bar Dispatcher
 * @class SearchBarDispatcherExtended
 * @extends SourceSearchBarDispatcher
 * @namespace AllHomePwafe/Store/ProductListInfo/Dispatcher/ProductListInfoDispatcher */
export class ProductListInfoDispatcher extends SourceProductListInfoDispatcher {
    prepareRequest(options, dispatch) {
        const productOptions = {
            ...options,
            args: { ...options.args, search: encodeURIComponent(options.args.search) }
        };

        return super.prepareRequest(productOptions, dispatch);
    }
}

export default new ProductListInfoDispatcher();
