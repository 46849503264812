import { SET_CURRENT_STEP } from './CurrentStep.action';
/** @namespace AllHomePwafe/Store/CurrentStep/Reducer/getInitialState */
export const getInitialState = () => ({
    step: false
});

/** @namespace AllHomePwafe/Store/CurrentStep/Reducer/CurrentStepReducer */
export const CurrentStepReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SET_CURRENT_STEP:
        const { step } = action;
        return {
            ...state,
            step
        };

    default:
        return state;
    }
};

export default CurrentStepReducer;
