import sourceProductListReducer, { getInitialState } from 'SourceStore/ProductList/ProductList.reducer.js';
import { REMOVE_FLASH_SALE_FROM_PRODUCT_LIST_ITEM } from 'Store/ProductList/ProductList.action';
import { getPriceRangeWithDiscount } from 'Util/Product/Product';

/** @namespace AllHomePwafe/Store/ProductList/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case REMOVE_FLASH_SALE_FROM_PRODUCT_LIST_ITEM:
        const { pages = {} } = state;
        const newPages = { ...pages };
        const { productId } = action;

        if (!Object.keys(newPages).length) {
            return state;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const pageKey of Object.keys(newPages)) {
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const index in newPages[pageKey]) {
                const product = newPages[pageKey][index];
                const { id } = product;
                const { flash_sale, ...updatedProduct } = product; // Removing the flash sale object here
                if (id === productId) {
                    if (!flash_sale) {
                        return state;
                    }
                    newPages[pageKey][index] = {
                        ...updatedProduct,
                        price_range: getPriceRangeWithDiscount(updatedProduct)
                    };
                }
            }
        }

        return {
            ...state,
            pages: newPages
        };

    default:
        return sourceProductListReducer(state, action);
    }
};

export default ProductListReducer;
