import {
    ConfigQuery as SourceConfigQuery
} from 'SourceQuery/Config.query';
import { Field } from 'Util/Query';

/** @namespace AllHomePwafe/Query/Config/Query/ConfigQuery */
export class ConfigQuery extends SourceConfigQuery {
    getLabelInnerConfigField() {
        return new Field('configs')
            .addFieldList([
                'displayPosition',
                'displayStyle',
                'maxLabelsPdp',
                'maxLabelsListing',
                'saleLabelBasedOnSpecPrice',
                'showSaleLabelDescPerc',
                'showSaleDiscAmnt',
                'saleLabelText',
                'saleLabelPosition',
                'salesCSS'
            ]);
    }

    __getShopByLookConfigFields() {
        return [
            'lookbookslider_general_show_product_desc',
            'lookbookslider_general_show_add_to_cart',
            'lookbookslider_general_show_pinit_button',
            'lookbookslider_general_hotspot_icon',
            'lookbookslider_general_landing_page_title',
            'lookbookslider_general_enable_add_to_cart_button',
            'lookbookslider_general_add_to_cart_button_title',
            'lookbookslider_general_enable_similar_products',
            'lookbookslider_general_enable_related_products',
            'lookbookslider_general_related_product_title',
            'lookbookslider_general_related_product_limit',
            'lookbookslider_general_enable_home_page_section',
            'lookbookslider_general_home_page_shop_by_look_id'
        ];
    }

    __getFlashSaleConfigFields() {
        return [
            'flashsale_show_sale_block_plp',
            'flashsale_show_sale_block_pdp',
            'flashsale_show_sale_block_home_page',
            'flashsale_show_sale_block_cart',
            'flashsale_limit_each_product_to_each_customer',
            'flashsale_pwa_upcoming_deal_category',
            'flashsale_pwa_running_deal_category',
            'flashsale_active',
            'flashsale_products_per_flash_sale',
            'flashsale_to_load'
        ];
    }

    __getStoreLocatorConfig() {
        return [
            'kslocator_locator_api',
            'kslocator_general_url',
            'kslocator_general_meta_title',
            'kslocator_general_meta_description',
            'kslocator_locator_linktext',
            'kslocator_locator_zoom',
            'kslocator_locator_distance',
            'kslocator_locator_template',
            'kslocator_locator_close_text',
            'kslocator_locator_open_text',
            'kslocator_locator_show_distance'
        ];
    }

    __getSocialMediaConfigFields() {
        return [
            'kemana_sociallogin_facebook_app_id',
            'kemana_sociallogin_google_app_id',
            'kemana_sociallogin_general_enabled',
            'kemana_sociallogin_facebook_is_enabled',
            'kemana_sociallogin_google_is_enabled',
            'kemana_sociallogin_apple_enabled',
            'kemana_sociallogin_apple_client_id'
        ];
    }

    _getStoreConfigFields() {
        return [
            ...super._getStoreConfigFields(),
            ...this.__getShopByLookConfigFields(),
            ...this.__getStoreLocatorConfig(),
            ...this.__getFlashSaleConfigFields(),
            ...this.__getSocialMediaConfigFields(),
            'minimum_order_enabled',
            'minimum_order_amount',
            'minimum_order_msg',
            'minimum_include_discount_amount',
            'sales_intermediate_threshold',
            'sales_intermediate_message',
            'checkout_sidebar_max_items_display_count',
            'sales_intermediate_message',
            'store_code',
            'store_name',
            'is_default_store',
            'store_group_code',
            'is_default_store_group',
            'locale',
            'base_currency_code',
            'default_display_currency_code',
            'timezone',
            'weight_unit',
            'base_url',
            'base_link_url',
            'base_static_url',
            'base_media_url',
            'secure_base_url',
            'secure_base_link_url',
            'secure_base_static_url',
            'secure_base_media_url',
            'grid_per_page_values',
            'grid_per_page',
            'installment_popup_text',
            'installment_items_message',
            'installment_items_header',
            'blog_seo_meta_title',
            'blog_seo_meta_description',
            'blog_seo_meta_keywords',
            'blog_seo_meta_robots',
            'mageworx_seo_base_robots_noindex_follow_pages',
            'mageworx_seo_base_robots_noindex_follow_user_pages',
            'mageworx_seo_base_robots_noindex_nofollow_user_pages',
            'mageworx_seo_base_robots_attribute_settings',
            'mageworx_seo_base_robots_category_ln_pages_robots',
            'mageworx_seo_base_robots_count_filters_for_noindex',
            'disclaimer_message',
            'disclaimer_message_header',
            'payment_alleasy_customer_message',
            ...this.getExclusivePriceConfigs()
        ];
    }

    getLabelConfig() {
        return new Field('km_getLabels')
            .addArgument('website_code', 'String', '1')
            .addFieldList([
                this.getLabelInnerConfigField()
            ]);
    }

    getExclusivePriceConfigs() {
        return [
            'exclusiveprice_general_pdp_label',
            'exclusiveprice_general_plp_label',
            'exclusiveprice_general_pdp_shipping_msg',
            'exclusiveprice_general_cart_shipping_msg'
        ];
    }
}

export default new ConfigQuery();
