import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import JsonLd from './JsonLd.component';

/** @namespace AllHomePwafe/Component/JsonLd/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    jsonLdItems: state.JsonLdReducer.items
});

/** @namespace AllHomePwafe/Component/JsonLd/Container/JsonLdContainer */
export class JsonLdContainer extends PureComponent {
    static propTypes = {
        default_description: PropTypes.string
    };

    static defaultProps = {
        default_description: ''
    };

    render() {
        return (
            <JsonLd
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
            />
        );
    }
}

/** @namespace AllHomePwafe/Component/JsonLd/Container/mapDispatchToProps */

export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JsonLdContainer);
