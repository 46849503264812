import Link from 'Component/Link';
import { COMPARE_LABEL, COMPARE_URL } from 'Component/ProductCompare/ProductCompare.config';
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';

import './Menu.override.style.scss';

/** @namespace AllHomePwafe/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    renderAdditionalInformation() {
        return null;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        this.scrollToTop();
    }

    /**
     * @extends to manually set left position of the active submenu item
     * Menu items left position exceeds or gets close to the menu width will be left aligned
     */

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children, url: { pathname = false } } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack, closeMenu } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        const menuElem = document.querySelector(`.Menu-Item [href="${pathname}"]`);
        const menuWidth = document.querySelector('.Menu-MainCategories').clientWidth;
        const { left: leftBound } = menuElem.getBoundingClientRect();
        const subMenuItemWidth = 230;
        const activeMenuWidth = menuElem.clientWidth;
        const left = leftBound + (activeMenuWidth * 2) > menuWidth
            ? leftBound - (subMenuItemWidth - activeMenuWidth) : leftBound;

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
              style={ { left } }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                    { this.renderAdditionalInformation() }
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />
            </div>
        );
    };

    render() {
        const { closeMenu, setMenuStatus } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >
                    <Link
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => setMenuStatus(false) }
                      to={ `${ COMPARE_URL }` }
                      block="MenuItem"
                      elem="CompareLink"
                    >
                        { COMPARE_LABEL }
                    </Link>
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default MenuComponent;
