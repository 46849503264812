import { connect } from 'react-redux';

import { MENU, MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';

export const MenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Menu/Menu.dispatcher'
);

/** @namespace AllHomePwafe/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    menuStatus: state.MenuReducer.status
});

/** @namespace AllHomePwafe/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setMenuStatus: (options) => MenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.setMenuStatus(dispatch, options)
    )
});
/** @namespace AllHomePwafe/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    __construct() {
        this.state = {
            activeMenuItemsStack: [],
            menu: {},
            lastActiveMenuItemsStack: []
        };
    }

    resetActiveMenuItemsStack() {
        this.setState({ activeMenuItemsStack: [] });
    }

    componentDidUpdate() {
        const { menuStatus, device: { isMobile } } = this.props;

        const { activeMenuItemsStack } = this.state;
        if (isMobile && !menuStatus && activeMenuItemsStack.length) {
            this.resetActiveMenuItemsStack();
        }
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { changeHeaderState } = this.props;
        const { activeMenuItemsStack } = this.state;
        const { item_id, title } = activeSubcategory;

        e.stopPropagation();

        changeHeaderState({
            name: MENU_SUBCATEGORY,
            force: true,
            title,
            onBackClick: this.handleHeaderBackClick
        });

        const newActiveMenuItemsStack = [item_id, ...activeMenuItemsStack];
        this.setState({
            activeMenuItemsStack: newActiveMenuItemsStack,
            lastActiveMenuItemsStack: activeMenuItemsStack
        });
    }

    handleHeaderBackClick = () => {
        const { activeMenuItemsStack, lastActiveMenuItemsStack } = this.state;
        const { changeHeaderState } = this.props;

        const prevMenuStack = JSON.stringify(activeMenuItemsStack) === JSON.stringify(lastActiveMenuItemsStack)
            ? [] : lastActiveMenuItemsStack;

        this.setState({
            activeMenuItemsStack: prevMenuStack
        });

        if (!prevMenuStack.length) {
            changeHeaderState({
                name: MENU,
                search: true
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
