import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.style';

/** @namespace AllHomePwafe/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {
    renderNativeSelect() {
        const {
            name,
            id,
            onChange,
            selectOptions,
            formRef,
            value,
            isDisabled,
            isSelectExpanded: isExpanded,
            autocomplete,
            skipValue
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              autoComplete={ autocomplete }
              mods={ { isExpanded, isDisabled } }
              ref={ formRef }
              name={ name }
              id={ id }
              disabled={ isDisabled }
              tabIndex="0"
              value={ value || '' }
              onChange={ onChange }
              data-skip-value={ skipValue }
              aria-label={ __('Select drop-down') }
            >
                { this.renderPlaceholder() }
                { selectOptions.map(this.renderNativeOption) }
            </select>
        );
    }
}

export default FieldSelectComponent;
