import { connect } from 'react-redux';

import {
    mapDispatchToProps, mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer
} from 'SourceComponent/ProductPrice/ProductPrice.container';
import { isUpcomingFlashSale } from 'Util/FlashSale';
import {
    getFormattedFinalPrice, getPriceWithOptions,
    getProductDiscountPercentage, getRoundedRegularPrice
} from 'Util/Price';
import { CONFIGURABLE, SIMPLE } from 'Util/Product/Types';

import ProductPrice from './ProductPrice.component';

/** @namespace AllHomePwafe/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    getPriceWithOptions(finalPrice) {
        return getPriceWithOptions({ finalPrice, productProps: this.props });
    }

    __getFlashUpdatedData(product) {
        if (isUpcomingFlashSale(product) && !this.__isPLPPriceBlock()) {
            return { product: { ...product, flash_sale: {} } };
        }

        return product;
    }

    __getProductWitVariant() {
        const {
            product: { variants = [], type_id = SIMPLE } = {},
            product,
            configurableVariantIndex
        } = this.props;

        if (variants?.length && type_id === CONFIGURABLE && variants[configurableVariantIndex]) {
            return this.__getFlashUpdatedData(variants[configurableVariantIndex]);
        }

        return this.__getFlashUpdatedData(product);
    }

    __isPLPPriceBlock() {
        const { isPLP } = this.props;

        return isPLP;
    }

    __getPriceRangeWithFlashSale(product) {
        const { price } = this.props;

        const price_range = product?.price_range || price;

        const { minimum_price = {} } = price_range;

        if (isUpcomingFlashSale(product) && !this.__isPLPPriceBlock()) {
            return {
                ...price_range, minimum_price: { ...minimum_price, discount: { percent_off: 0 } }
            };
        }

        return price_range;
    }

    __getProductProps() {
        const product = this.__getProductWitVariant();
        return {
            productProps: {
                ...this.props,
                product,
                price: this.__getPriceRangeWithFlashSale(product)
            }
        };
    }

    getFormattedFinalPrice() {
        return getFormattedFinalPrice(this.__getProductProps());
    }

    getRoundedRegularPrice() {
        return getRoundedRegularPrice(this.__getProductProps());
    }

    getAdditionalDiscountPrice() {
        const {
            price: {
                minimum_price: {
                    second_strikethrough_price: {
                        value: additionalDiscount = 0
                    } = {},
                    option_prices = []
                } = {}
            } = {},
            productOptionsData: {
                productOptions = []
            } = {}
        } = this.props;

        const [{ title: selectedOptionTitle } = {}] = productOptions;

        const { additional_discount: additionalDiscountPrice } = option_prices
            ?.find(({ text }) => text === selectedOptionTitle) || {};

        return additionalDiscountPrice || additionalDiscount;
    }

    containerProps = () => {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        currency: priceCurrency
                    } = {},
                    second_strikethrough_price: {
                        value: additionalDiscount
                    } = {}
                } = {}
            } = {}
        } = this.props;

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();
        const additionalDiscountPrice = this.getAdditionalDiscountPrice();

        return {
            additionalDiscount,
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice,
            formattedSubPrice,
            additionalDiscountPrice
        };
    };

    render() {
        return (
            <ProductPrice
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
              discountPercentage={ getProductDiscountPercentage(this.__getProductProps()) }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
